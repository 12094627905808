<template>
    <div>
        <div class="label">
            <label>Aggiungi nuovo cliente</label>
        </div>
        <div>
            <v-form ref="formAddUser" v-model="valid" lazy-validation>
                <v-text-field v-model="nome" :rules="textRule" label="Nome" required />
                <v-text-field v-model="cognome" :rules="textRule" label="Cognome" required />
                <label @click="generateEmail" class="generate"><i class="fas fa-sync-alt"></i> Genera email</label>
                <v-text-field v-model="email" :rules="emailRule" label="E-mail" required />
                <v-text-field v-model="phone" label="Telefono" />
                <v-btn class="mt-2" color="primary" :disabled="!valid" @click="addUserClient">Aggiungi</v-btn>
            </v-form>
        </div>
    </div>
</template>
<script>
    import apiUser from '@/components/Presa/cliente/js/user'
    import { generalFunction } from "@/components/js/generalFunction";
    import { validationRules } from "@/components/js/validationRules";

    export default {
        name: 'AggiungiCliente',
        data() {
            return {
                valid: true,
                nome: undefined,
                cognome: undefined,
                email: undefined,
                phone: undefined,
                textRule: validationRules.textRule(),
                emailRule: validationRules.emailRule(),
            }
        },
        methods: {
            async addUserClient() {
                if (this.$refs.formAddUser.validate()) {
                    let data = {
                        nome: this.nome,
                        cognome: this.cognome,
                        email: this.email,
                        telefono: this.phone
                    };
                    let token = generalFunction.getToken();
                    if (token) {
                        let user = await apiUser.addUserClient(token, data);
                        if (user) this.$emit('closeModalAddUser', user);
                    }
                    else alert("Esiste già un utente con questa mail");
                }
            },
            async generateEmail() {
                if (this.nome || this.cognome) {
                    let data = {
                        nome: this.nome.replace(/[^\w\s]/gi, ''),
                        cognome: this.cognome.replace(/[^\w\s]/gi, ''),
                    };
                    let token = generalFunction.getToken();
                    if (token) {
                        let email = await apiUser.autoGenerateEmail(token, data);
                        if (email) this.email = email;
                        else alert('Spiacenti si è verificato un errore');
                    }
                } else alert('Inserisci almeno il nome o il cognome');
            }
        }
    }
</script>
