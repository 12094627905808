<template>
    <div>
        <div class="box-table summary">
            <v-simple-table>
                <thead>
                <tr>
                    <th class="text-left">Presa</th>
                    <th class="text-left">Consegna</th>
                    <th v-if="!viewForClient && !viewFinalClient" class="text-left">Tempo stimato</th>
                    <th v-if="!$route.meta.stampa" class="text-center">Completata</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{{ dataPresa }}</td>
                    <td>{{ dataConsegna }}</td>
                    <td v-if="!viewForClient && !viewFinalClient"><span >{{ tempoStimato }}</span></td>
                    <td v-if="!$route.meta.stampa" class="text-center">
                        <label class="fancy-checkbox in-form">
                            <input disabled type="checkbox" :checked="presa && presa.completata">
                            <i class="fas fa-check-circle checked"/>
                            <i class="far fa-circle unchecked"/>
                        </label>
                    </td>
                </tr>
                </tbody>
                <thead>
                <tr>
                    <th v-if="!viewForClient" class="text-left">Manodopera</th>
                    <th v-if="!viewForClient" class="text-left">Lavorazioni esterne</th>
                    <th v-if="!viewForClient" class="text-left">Articoli</th>
                    <th v-if="!viewForClient" class="text-left">Totale</th>
                    <th v-if="!viewForClient && !viewForClient && !viewFinalClient" class="text-center">Pagato</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td v-if="!viewForClient">{{ numberFormat(manodopera) }} €</td>
                    <td v-if="!viewForClient">{{ numberFormat(lavorazioni_esterne) }} €</td>
                    <td v-if="!viewForClient">{{ numberFormat(prezzoArticoli) }} €</td>
                    <td v-if="!viewForClient"><b>{{ numberFormat(totale) }} €</b></td>
                    <td v-if="!viewForClient && !viewForClient && !viewFinalClient" class="text-center">
                        <label class="fancy-checkbox in-form">
                            <input disabled type="checkbox" :checked="presa && presa.pagamento && presa.pagamento.pagato">
                            <i class="fas fa-check-circle checked"/>
                            <i class="far fa-circle unchecked"/>
                        </label>
                    </td>
                </tr>
                <tr class="box-table dettagli-gestione">
                    <td v-if="!$route.meta.stampa">
                        <button class="button">
                            <label class="fancy-checkbox in-form">
                                <input disabled type="checkbox" :checked="operatoreAssociato">
                                <i class="fas fa-check-circle checked"/>
                                <i class="far fa-circle unchecked"/>
                            </label>
                            <span>Associato</span>
                        </button>
                    </td>
                    <td colspan="1" v-if="rNomeOperatore">
                        <b v-if="$route.meta.stampa">Operatore: </b>
                        <i v-if="!$route.meta.stampa" class="fas fa-circle" style="font-size: 14px !important;" :style="{ color: rColorOperatore + '!important' }"/>
                        {{ rNomeOperatore }}
                    </td>
                    <td colspan="2">Data lavoro: {{ dataLavoro }}</td>
                </tr>
                </tbody>
            </v-simple-table>
        </div>
    </div>
</template>
<script>
    import { momentDate } from "@/components/js/formatDate";
    import apiLavorazioni from '@/components/Presa/lavorazioni/js/lavorazioni';
    import { generalFunction } from "@/components/js/generalFunction";

    export default {
        name: "LavorazioneSummary",
        props: [
            'presa',
            'viewForClient',
            'viewFinalClient'
        ],
        data() {
            return {
                allLavorazioni: [],
                dataPresa: undefined,
                dataConsegna: undefined,
                tempoStimato: '00:00',
                totale: 0,
                manodopera: 0,
                lavorazioni_esterne: 0,
                prezzoArticoli: 0,
                statoPresa: 0,
                dataLavoro: undefined,
                operatoreAssociato: false,
                rColorOperatore: '#ccc',
                rNomeOperatore: undefined
            }
        },
        async created() {
            let token = await generalFunction.getToken();
            if (token) {
                let lavorazioni = await apiLavorazioni.getAllLavorazioni(token);
                if (lavorazioni[1])
                    this.allLavorazioni = lavorazioni[1];
            }
            this.initPresa();
        },
        methods: {
            initPresa() {
                if (this.presa) {
                    let tempoStimato = '00:00';
                    let totale = 0;
                    let manodopera = 0;
                    let lavorazioni_esterne = 0;
                    let prezzoArticoli = 0;
                    if (this.presa.data_presa)
                        this.dataPresa = momentDate(this.presa.data_presa);

                    if (this.presa.data_consegna)
                        this.dataConsegna = momentDate(this.presa.data_consegna);

                    if (this.presa.stato)
                        this.statoPresa = this.presa.stato;

                    if (this.presa.data_lavoro)
                        this.dataLavoro = momentDate(this.presa.data_lavoro);
                    if (
                        this.presa.operatore_associato &&
                        this.presa.operatore &&
                        this.presa.operatore[0]
                    ) {
                        this.operatoreAssociato = true;

                        if (this.presa.operatore[0].nome)
                            this.rNomeOperatore = this.presa.operatore[0].nome;

                        if (this.presa.operatore[0].colore)
                            this.rColorOperatore = this.presa.operatore[0].colore;
                    }

                    if (this.presa.lavorazioni) {
                        for (let lavorazionePresa of this.presa.lavorazioni) {
                            if (
                                lavorazionePresa.id_lavorazione &&
                                lavorazionePresa.id_valore
                            ) {
                                let lavorazione = this.allLavorazioni.find(lavorazione => {
                                    return lavorazione._id === lavorazionePresa.id_lavorazione;
                                });

                                if (
                                    lavorazione &&
                                    lavorazione.dettagli_valore &&
                                    lavorazione.dettagli_valore.lavorazioni_officina &&
                                    lavorazione.dettagli_valore.lavorazioni_officina.opzioni
                                ) {
                                    let option = lavorazione.dettagli_valore.lavorazioni_officina.opzioni.find(option => {
                                        return option._id === lavorazionePresa.id_valore;
                                    });
                                    if (option) {
                                        if (option.tempo_stimato)
                                            tempoStimato = generalFunction.sumHours(tempoStimato, option.tempo_stimato, ':');

                                        if (
                                            option.prezzo &&
                                            !isNaN(option.prezzo) &&
                                            option.prezzo > 0 &&
                                            lavorazione.rCategoria !== 'esterna'
                                        ) manodopera += Number(option.prezzo);

										if (lavorazione.rCategoria === 'esterna') {
                                            lavorazioni_esterne += lavorazionePresa.prezzo;
											option.prezzo = lavorazionePresa.prezzo
										}
                                    }
                                }
                            }
                        }
                    }

                    if (this.presa.tempo_previsto)
                        tempoStimato = this.presa.tempo_previsto;

                    if (this.presa.articoli) {
                        for (let articoliPresa of this.presa.articoli) {
                            if (
                                articoliPresa.prezzo &&
                                !isNaN(articoliPresa.prezzo) &&
                                articoliPresa.prezzo > 0
                            ) prezzoArticoli += Number(articoliPresa.prezzo);
                        }
                    }

                    this.tempoStimato = tempoStimato;
                    if(this.presa['manodopera']) manodopera = this.presa['manodopera'];
                    if(this.presa['lavorazioni_esterne']) lavorazioni_esterne = this.presa['lavorazioni_esterne'];
                    this.manodopera = manodopera;
                    this.lavorazioni_esterne = lavorazioni_esterne;
                    this.prezzoArticoli = prezzoArticoli;
                    totale += manodopera;
                    totale += lavorazioni_esterne;
                    totale += prezzoArticoli;
                    this.totale = totale;
                }
            },
            numberFormat(value = 0) {
                if(!isNaN(value))
                    return parseFloat(value).toFixed(2).split(".").join(',');
                return 0;
            }
        },
        watch: {
            presa: function () {
                this.initPresa();
            }
        }
    }
</script>
