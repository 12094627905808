import axios from 'axios';
import config  from '@/config/config';

export default {
    async searchUsers(params) {
        return await axios.get(config.endpoint + "/api/officina/ricerca_utente?" + params).then(response => {
            return response.data;
        });
    },
    async addUserClient(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_signup_client_officina?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getUserClient(params) {
        return await axios.get  (config.endpoint + "/api/get_user_client?search=_id,"+params+"&cache="+Math.random()*100000).then(response => {
            return response.data;
        });
    },
    async addNewBrand(token, params) {
        return await axios.post(config.endpoint + "/api/aggiungi_valore_attributo?token="+token, params).then(response => {
            return response.data;
        });
    },
    async addUserBike(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_init_add_bici_utente?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteUserBike(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_delete_bici_utente?token="+token, params).then(response => {
            return response.data;
        });
    },
    async autoGenerateEmail(token, params) {
        return await axios.post(config.endpoint + "/api/generazione_automatica_email?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getOperationBike(token, params) {
        return await axios.get(config.endpoint + "/api/ciclimattio_get_operazioni_bici?token="+token+"&id_bici="+params).then(response => {
            return response.data;
        });
    },
}