<template>
	<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
	<v-tab-item class="lavorazione">
		<v-row>
			<v-col xs="12">
				<label>Note Interne</label>
				<v-textarea v-model="noteInterne" solo name="input-7-4"/>
			</v-col>
		</v-row>
		<v-row class="mt-4">
			<v-col xs="12">
				<label>Seleziona una lavorazione</label>
				<v-tabs class="v-tabs--grow open-tab" v-model="tabSelected">
					<v-tabs-slider/>
					<v-tab v-for="tab in tabs" :key="tab.value" :href="'#'+tab.value">
						{{ tab.nome }}
					</v-tab>
					<!--v-spacer/-->
					<!--i class="mdi mdi-magnify"/-->
					<v-tab-item v-for="tab in tabs" :key="tab.value" :value="tab.value">
						<v-expansion-panels>
							<v-expansion-panel v-for="lavorazione in allLavorazioni" :key="lavorazione._id"  v-if="lavorazione.rCategoria === tab.value">
								<v-expansion-panel-header>
									<v-container fluid>
										<v-row no-gutters>
											<v-col cols="11">
												{{ lavorazione.rName }}
											</v-col>
											<v-col cols="1">
												<v-btn
													icon
													style="float: right !important; margin-right: "
													color="success"
													@click="openDialogOpzione(lavorazione._id)"
												>
													<v-icon large style="font-size: 28px !important;">
														fas fa-plus-circle
													</v-icon>
												</v-btn>
											</v-col>
										</v-row>
									</v-container>
								</v-expansion-panel-header>
								<v-expansion-panel-content v-if="lavorazione.rCategoria && lavorazione.rCategoria !== 'esterna'">
									<v-row>
										<v-checkbox class="mx-2"
													v-for="option of lavorazione.rOptions"
													v-model="selectedOptions"
													:key="option._id"
													:label="option.rName"
													:value="option"/>
									</v-row>
								</v-expansion-panel-content>
								<v-expansion-panel-content v-else>
									<v-row v-for="option of lavorazione.rOptions" :key="option._id" class="my-2">
										<v-col cols="6">
											<label>{{ option.rName }}</label>
											<v-text-field v-model="option.prezzo" @input="selectLavorazioneEsterna(option)"/>
										</v-col>
									</v-row>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
						<v-btn
							icon
							class="float-right"
							color="success"
							large
							@click="dialogAttributoValue = true;"
						>
							<v-icon large class="large_button" style="font-size: 36px !important;">
								fas fa-plus-circle
							</v-icon>
						</v-btn>
					</v-tab-item>
				</v-tabs>
			</v-col>
		</v-row>
		<v-dialog
			v-model="dialogAttributoValue"
			max-width="300px"
		>
			<v-card>
				<v-card-title class="mb-0"><h4>Inserisci Nome</h4></v-card-title>
				<v-divider class="mb-3"></v-divider>
				<v-card-text style="height: 300px;">
					<v-text-field
						label="Nome"
						outlined
						v-model="valueName"
					></v-text-field>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn
						color="blue darken-1"
						text
						@click="dialogAttributoValue = false"
					>
						Chiudi
					</v-btn>
					<v-btn
						color="blue darken-1"
						text
						@click="creaAttributoValore"
					>
						Crea
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="dialogOpzione"
			max-width="300px"
		>
			<v-card>
				<v-card-title class="mb-0"><h4>Dati Opzione</h4></v-card-title>
				<v-divider class="mb-3"></v-divider>
				<v-card-text style="height: 300px;">
					<v-text-field
						label="Nome"
						outlined
						v-model="nomeOpzione"
					></v-text-field>
					<v-text-field
						label="Prezzo"
						type="number"
						outlined
						v-model="prezzoOpzione"
					></v-text-field>
					<v-menu
						ref="menu"
						v-model="menu2"
						:close-on-content-click="false"
						:return-value.sync="tempoOpzione"
						transition="scale-transition"
						offset-y
						max-width="290px"
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="tempoOpzione"
								label="Durata"
								prepend-icon="mdi-clock-time-four-outline"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-time-picker
							v-if="menu2"
							v-model="tempoOpzione"
							full-width
							@click:minute="$refs.menu.save(tempoOpzione)"
						></v-time-picker>
					</v-menu>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn
						color="blue darken-1"
						text
						@click="dialogOpzione = false"
					>
						Chiudi
					</v-btn>
					<v-btn
						color="blue darken-1"
						text
						@click="creaOpzione"
					>
						Crea
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-tab-item>
</template>
<script>
import apiLavorazioni from '@/components/Presa/lavorazioni/js/lavorazioni';
import { generalFunction } from "@/components/js/generalFunction";
import config from '@/config/config';

export default {
	name: 'Lavorazioni',
	props: ['presa'],
	data() {
		return {
			noteInterne: undefined,
			allLavorazioni: [],
			selectedOptions: [],
			tabs: [
				{ nome: 'Cura', value: 'cura' },
				{ nome: 'Telaio', value: 'telaio' },
				{ nome: 'Impianto frenante', value: 'impianto_frenante' },
				{ nome: 'Trasmissione', value: 'trasmissione' },
				{ nome: 'Ruote', value: 'ruote' },
				{ nome: 'Esterne', value: 'esterna' }
			],
			dialogOpzione: false,
			dialogAttributoValue: false,
			valueName: '',
			tabSelected: undefined,
			nomeOpzione: '',
			prezzoOpzione: 0,
			tempoOpzione: undefined,
			menu2: false
		}
	},
	async created() {
		let token = await generalFunction.getToken();
		if (token) {
			await this.initDataLavorazioni(token);
		} else alert('Spiacenti si è verificato un errore');
		await this.controlEditPresa();
	},
	methods: {
		async initDataLavorazioni(token) {
			let lavorazioni = await apiLavorazioni.getAllLavorazioni(token);
			if (lavorazioni[1]) {
				for (let lavorazione of lavorazioni[1]) {
					lavorazione.rName = 'Senza nome';
					if (lavorazione.it && lavorazione.it.nome)
						lavorazione.rName = lavorazione.it.nome;

					lavorazione.rCategoria = undefined;
					lavorazione.rOptions = [];
					if (
						lavorazione.dettagli_valore &&
						lavorazione.dettagli_valore.lavorazioni_officina
					) {
						if (lavorazione.dettagli_valore.lavorazioni_officina.categoria)
							lavorazione.rCategoria = lavorazione.dettagli_valore.lavorazioni_officina.categoria;

						if (lavorazione.dettagli_valore.lavorazioni_officina.opzioni) {
							for (let opzione of lavorazione.dettagli_valore.lavorazioni_officina.opzioni) {
								opzione.id_lavorazione = lavorazione._id;
								opzione.rName = 'Senza nome';
								if (opzione.nome) opzione.rName = opzione.nome;
							}
							lavorazione.rOptions = lavorazione.dettagli_valore.lavorazioni_officina.opzioni;
						}
					}
				}
			}
			this.allLavorazioni = lavorazioni[1];
		},
		controlEditPresa() {
			let selectedOptions = [];
			if (
				this.presa &&
				Object.entries(this.presa).length > 0
			) {
				if (this.presa.note_interne)
					this.noteInterne = this.presa.note_interne;

				if (this.presa.richieste)
					this.richieste = this.presa.richieste;

				if (
					this.presa.lavorazioni &&
					this.presa.lavorazioni.length > 0
				) {
					for (let mainLavorazione of this.allLavorazioni) {
						if (
							mainLavorazione &&
							mainLavorazione.rOptions &&
							mainLavorazione.rOptions.length > 0
						) {
							for (let lavorazione of this.presa.lavorazioni) {
								if (
									mainLavorazione._id === lavorazione.id_lavorazione &&
									lavorazione.id_valore
								) {
									let option = mainLavorazione.rOptions.find(option => {
										return option._id === lavorazione.id_valore;
									});
									if (Object.entries(option).length > 0) {
										if (mainLavorazione.rCategoria === 'esterna')
											option.prezzo = lavorazione.prezzo
										selectedOptions.push(option);
									}
								}
							}
						}
					}
				}
			}
			this.selectedOptions = [ ...selectedOptions ];
		},
		async creaAttributoValore(){
			if(this.valueName){
				let data = { "id_attributo": config.id_lavorazioni, nome: this.valueName, etichetta: this.tabSelected };
				let token = await generalFunction.getToken();
				let id_value = await apiLavorazioni.creaNuovoAttributoValore(token, data);
				let dataAttributo = {
					archiviato: false,
					first_save: true,
					dettagli_valore: {
						lavorazioni_officina: {
							categoria: this.tabSelected,
							opzioni: []
						},
					},
					id_attributo: config.id_lavorazioni,
					it: {
						nome: this.valueName,
						nome_lower: this.valueName.toLowerCase()
					},
					rCategoria: this.tabSelected,
					rName: this.valueName,
					rOptions: [],
					_id: id_value
				}

				this.allLavorazioni.push(dataAttributo);
				this.valueName = '';
			}
			this.dialogAttributoValue = false;
		},
		openDialogOpzione(val) {
			this.dialogOpzione = true;
			this.id_attributo_selected = val;
		},
		async creaOpzione() {
			if(
				this.id_attributo_selected &&
				this.nomeOpzione
			){
				let data = { id_valore: this.id_attributo_selected, nome: this.nomeOpzione, prezzo: this.prezzoOpzione, tempo: this.tempoOpzione };
				let token = await generalFunction.getToken();
				let id_opzione = await apiLavorazioni.creaNuovaOpzione(token, data);
				if(id_opzione){
					let dataOpzione = {
						id_lavorazione: this.id_attributo_selected,
						_id: id_opzione,
						nome: this.nomeOpzione,
						rName: this.nomeOpzione,
						tempo_stimato: this.tempoOpzione,
						prezzo: this.prezzoOpzione
					};
					for (let lavorazione of this.allLavorazioni) {
						if(lavorazione._id == this.id_attributo_selected){
							lavorazione.rOptions.push(dataOpzione);
							this.selectedOptions.push(dataOpzione);
						}
					}
				}
				this.dialogOpzione = false;
				this.id_attributo_selected = undefined;
				this.nomeOpzione = '';
				this.prezzoOpzione = 0;
				this.tempoOpzione = undefined;
			}
		},
		selectLavorazioneEsterna(option) {
			if (
				option.prezzo &&
				!isNaN(option.prezzo) &&
				Number(option.prezzo) > 0
			) {
				const indiceOggetto = this.selectedOptions.findIndex(obj => String(obj._id) === String(option._id));
				if (indiceOggetto !== -1) {
					this.selectedOptions[indiceOggetto] = option;
				} else this.selectedOptions.push(option);
			} else this.selectedOptions = this.selectedOptions.filter(obj => String(obj._id) !== String(option._id));
		},
	},
	watch: {
		presa() {
			this.controlEditPresa();
		}
	}
}
</script>

<style>
.v-time-picker-clock__ampm {
	display: none !important;
}
</style>