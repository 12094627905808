<template>
    <v-container fluid pl-4 pr-4 class="info-wrapper stampa">
        <v-row>
            <v-col md="12" class="turn-back">
                <div class="text-right pb-4 pr-3" @click="goRouterBack">
                    <i class="far fa-times-circle close"/>
                </div>
            </v-col>
            <v-col md="6">

                <table class="print-table">
                    <caption class="text-left" v-if="presa"><h6>Nr. Ordine: # {{ presa.numero }}</h6></caption>
                    <thead>
                    <tr>
                        <th class="text-left" v-if="presa && presa.user && presa.user[0]">Cliente</th>
                        <th></th>
                        <th class="text-left" v-if="presa && presa.richieste">Richieste Cliente</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colspan="2" v-if="presa && presa.user && presa.user[0]">
                            <p>
                                <span v-if="presa.user[0].nome">{{ presa.user[0].nome }}</span> <span v-if="presa.user[0].cognome">{{ presa.user[0].cognome }}<br></span>
                                <span v-if="presa.user[0].telefono">T. {{ presa.user[0].telefono }}<br></span>
                                <span v-if="presa.user[0].email">{{ presa.user[0].email }}</span>
                            </p>
                        </td>
                        <td colspan="2" v-if="presa && presa.richieste"><p>{{ presa.richieste }}</p></td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th class="text-left" v-if="bike">Bici</th>
                        <th v-if="bike"></th>
                        <th class="text-left" v-if="presa && presa.note_interne && !cliente && !finalClient">Note interne</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colspan="2" v-if="bike">
                            <p>
                                <span v-if="bike.modello">{{ bike.modello }} <br></span>
                                <span v-if="bike.numero_telaio">N. Telaio: {{ bike.numero_telaio }} <br></span>
                            </p>
                        </td>
                        <td colspan="2" v-if="presa && presa.note_interne && !cliente && !finalClient"><p>{{ presa.note_interne }}</p></td>
                    </tr>
                    </tbody>
                </table>
            </v-col>
            <v-col sm="12" md="6">
                <LavorazioneSummary :presa="presa" :viewForClient="cliente" :viewFinalClient="finalClient"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12" md="12">
                <v-simple-table class="info-order"
                                v-for="(category, index) in categories"
                                :key="index"
                                v-show="selectedLavorazioni.find(option => {
                                    return option.rCategoria === category.value;
                                })">
                    <thead>
                    <tr>
                        <th class="text-left">{{ category.nome }}</th>
                        <th class="text-left" v-if="!cliente && !finalClient"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="lavorazione in selectedLavorazioni"
                        :key="lavorazione._id"
                        v-show="lavorazione.rCategoria === category.value">
                        <td>{{ lavorazione.rName }} <span v-if="lavorazione.rOption && lavorazione.rOption.rName">- {{ lavorazione.rOption.rName }}</span></td>
                        <td class="text-right" v-if="(!cliente && !finalClient) && lavorazione.rOption && lavorazione.rOption.rTempoStimato && lavorazione.rOption.rTempoStimato !== '00:00'">
                            Tempo Stimato {{ lavorazione.rOption.rTempoStimato }} h
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
                <v-simple-table class="info-order" v-if="presa && presa.articoli && presa.articoli.length > 0">
                    <thead>
                    <tr>
                        <th class="text-left">Articolo</th>
                        <th class="text-left">Marca</th>
                        <th class="text-left">Quantit&agrave;</th>
                        <th class="text-left">Codice Interno</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(articolo, index) in presa.articoli" :key="articolo._id">
                        <td><b>{{ articolo.nome }}</b></td>
                        <td v-for="brand in brands" :key="brand._id+index" v-show="articolo.marca && brand._id === articolo.marca">
                            <span v-if="brand._id === articolo.marca">{{ brand.rName }}</span>
                        </td>
                        <td>{{ articolo.quantita }}</td>
                        <td>{{ articolo.codice }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import LavorazioneSummary from '@/components/gestione/LavorazioneSummary';
    import apiPresa from '@/components/Presa/js/presa';
    import apiLavorazioni from '@/components/Presa/lavorazioni/js/lavorazioni';
    import { generalFunction } from "@/components/js/generalFunction";
    import { EventBus } from "../../eventBus";

    export default {
        name: 'Stampa',
        components: { LavorazioneSummary },
        data() {
            return {
                idPresa: this.$route.query._id,
                presa: undefined,
                bike: undefined,
                categories: [
                    { nome: 'Cura', value: 'cura' },
                    { nome: 'Telaio', value: 'telaio' },
                    { nome: 'Impianto frenante', value: 'impianto_frenante' },
                    { nome: 'Trasmissione', value: 'trasmissione' },
                    { nome: 'Ruote', value: 'ruote' },
                ],
                allLavorazioni: [],
                selectedLavorazioni: [],
                brands: [],
                cliente: false,
                finalClient: false
            }
        },
        async created() {
            if (
                this.$route.query &&
                this.$route.query.cliente
            ) this.cliente = true;

            if (
                this.$route.query &&
                this.$route.query.finalClient
            ) this.finalClient = true;

            let brands = [] = await generalFunction.getBrands();
            if (brands[1]) {
                for (let brand of brands[1]) {
                    brand.rName = 'Senza Nome';
                    if (
                        brand &&
                        brand.it &&
                        brand.it.nome
                    ) brand.rName = brand.it.nome;
                }
            }
            this.brands = brands[1];

            this.initPresa();

            EventBus.$on('reloadDetailPresa', () => {
                this.initPresa();
            });

            let token = await generalFunction.getToken();
            if (token) {
                let lavorazioni = await apiLavorazioni.getAllLavorazioni(token);
                if (lavorazioni[1]) {
                    for (let lavorazione of lavorazioni[1]) {
                        lavorazione.rName = 'Senza nome';
                        if (lavorazione.it && lavorazione.it.nome)
                            lavorazione.rName = lavorazione.it.nome;

                        lavorazione.rCategoria = undefined;
                        lavorazione.rOptions = [];
                        if (
                            lavorazione.dettagli_valore &&
                            lavorazione.dettagli_valore.lavorazioni_officina
                        ) {
                            if (lavorazione.dettagli_valore.lavorazioni_officina.categoria)
                                lavorazione.rCategoria = lavorazione.dettagli_valore.lavorazioni_officina.categoria;

                            if (lavorazione.dettagli_valore.lavorazioni_officina.opzioni) {
                                for (let opzione of lavorazione.dettagli_valore.lavorazioni_officina.opzioni) {
                                    opzione.id_lavorazione = lavorazione._id;
                                    opzione.rName = 'Senza nome';
                                    if (opzione.nome) opzione.rName = opzione.nome;
                                }
                                lavorazione.rOptions = lavorazione.dettagli_valore.lavorazioni_officina.opzioni;
                            }
                        }
                    }
                }
                this.allLavorazioni = lavorazioni[1];
            } else alert('Spiacenti si è verificato un errore');

            await this.controlLavorazioniPresa();
            this.stampaPresa();
        },
        methods: {
            goRouterBack() {
                this.$router.back();
            },
            async initPresa() {
                let token = await generalFunction.getToken();
                if (
                    token &&
                    this.idPresa
                ) {
                    let presa = await apiPresa.getPresaWithDetails(token, this.idPresa);
                    if (presa) {
                        if (
                            presa.user &&
                            presa.user[0]
                        ) {
                            if (
                                presa.id_bici &&
                                presa.user[0].dettagli_utente &&
                                presa.user[0].dettagli_utente.bici
                            ) {
                                let bike = presa.user[0].dettagli_utente.bici.find(bike => {
                                    return bike._id === presa.id_bici;
                                });

                                if (bike) this.bike = bike;
                                else this.bike = {};
                            }
                        }
                    } else presa = {};
                    this.presa = presa;
                }
            },
            controlLavorazioniPresa() {
                let selectedLavorazioni = [];
                if (
                    this.presa &&
                    Object.entries(this.presa).length > 0
                ) {
                    if (
                        this.presa.lavorazioni &&
                        this.presa.lavorazioni.length > 0
                    ) {
                        for (let lavorazionePresa of this.presa.lavorazioni) {
                            let addLavorazione = {};
                            if (
                                lavorazionePresa.id_lavorazione &&
                                lavorazionePresa.id_valore
                            ) {
                                let lavorazione = this.allLavorazioni.find(lavorazione => {
                                    return lavorazione._id === lavorazionePresa.id_lavorazione;
                                });

                                if (
                                    lavorazione &&
                                    lavorazione.dettagli_valore &&
                                    lavorazione.dettagli_valore.lavorazioni_officina &&
                                    lavorazione.dettagli_valore.lavorazioni_officina.opzioni
                                ) {
                                    addLavorazione.rName = lavorazione.rName;
                                    addLavorazione.rCategoria = lavorazione.rCategoria;
                                    addLavorazione.rOption = {};
                                    let option = lavorazione.dettagli_valore.lavorazioni_officina.opzioni.find(option => {
                                        return option._id === lavorazionePresa.id_valore;
                                    });
                                    if (option) {
                                        addLavorazione.rOption.rName = option.rName;

                                        let tempoStimato = '00:00';
                                        if (option.tempo_stimato)
                                            tempoStimato = generalFunction.sumHours(tempoStimato, option.tempo_stimato, ':');
                                        addLavorazione.rOption.rTempoStimato = tempoStimato;
                                    }
                                }
                            }
                            selectedLavorazioni.push(addLavorazione);
                        }
                    }
                }
                this.selectedLavorazioni = [ ...selectedLavorazioni ];
            },
            stampaPresa() {
                setTimeout(() => {
                    window.print();    
                }, 2000);
            }
        }
    }
</script>
