import axios from 'axios';
import config  from '@/config/config';

export default {
    async getSpuntePresa() {
        return await axios.get(config.endpoint + "/api/get_attributo_by_permalink?permalink=spunte_presa").then(response => {
            return response.data;
        });
    },
    async getAllLavorazioni(token) {
        return await axios.get(config.endpoint + "/api/ciclimattio_get_lavorazioni?token="+ token).then(response => {
            return response.data;
        });
    },
    async creaNuovoAttributoValore(token,data) {
        return await axios.post(config.endpoint + "/api/ciclimattio_aggiungi_valore_attributo?token="+ token, data).then(response => {
            return response.data;
        });
    },
    async creaNuovaOpzione(token,data) {
        return await axios.post(config.endpoint + "/api/ciclimattio_init_opzione_attributo?token="+ token, data).then(response => {
            return response.data;
        });
    },
}