import Vue from "vue";
import VueRouter from "vue-router";
import { generalFunction } from '@/components/js/generalFunction';
import Error from '@/components/Error';
import Login from "@/components/login/Login";
import Home from "@/components/Home";
import Presa from "@/components/Presa/Presa";
import GestioneLavori from "@/components/gestione/GestioneLavori";
import Garanzie from "@/components/gestione/Garanzie";
import GestioneUtente from "@/components/utenti/GestioneUtente";
import Consegna from "@/components/gestione/consegna/Consegna";
import ListaLavori from "@/components/utenti/Lista";
import DettagliGestione from "@/components/gestione/dettagliGestione/DettagliGestione";
import Stampa from "@/components/stampa/Stampa";

Vue.use(VueRouter);

const routes = [
    { path: "*", component: Error },
    { path: "/", component: Home, name: "Home", meta: { requiresAuth: true, hideDashIcon: true } },
    { path: "/login", component: Login, name: "Login", meta: { hideNavigation: true } },
    { path: "/presa", component: Presa, name: "Presa", meta: { requiresAuth: true } },
    { path: "/gestione", component: GestioneLavori, name: "GestioneLavori", meta: { requiresAuth: true, gestione: true } },
    { path: "/garanzie", component: Garanzie, name: "Garanzie", meta: { requiresAuth: true } },
    { path: "/utente", component: GestioneUtente, name: "GestioneUtente", meta: { requiresAuth: true } },
    { path: "/consegna", component: Consegna, name: "Consegna", meta: { requiresAuth: true } },
    { path: "/lista", component: ListaLavori, name: "ListaLavori", meta: { requiresAuth: true } },
    { path: "/dettagli", component: DettagliGestione, name: "DettagliGestione", meta: { requiresAuth: true, addCol: true } },
    { path: "/stampa", component: Stampa, name: "Stampa", meta: { requiresAuth: true, hideNavigation: true, stampa: true } }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        let token = generalFunction.getToken();
        if (token) {
            generalFunction.controlLogin(token).then(response => {
                if (response) next();
                else next({path: '/login'});
            });
        } else next({ path: '/login' });
    } else next();
});

export default router;
