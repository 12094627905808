<template>
    <div class="gestisci-lavorazioni">
        <Tutti v-if="tab === 1" :nonAssegnati="false"/>
        <Tutti v-if="tab === 2" :nonAssegnati="true"/>
        <Associati v-if="tab === 3" :action="'lista'" :days="7"/>
    </div>
</template>
<script>
    import Tutti from '@/components/gestione/Tutti';
    import Associati from '@/components/gestione/associati/Associati';
    import { EventBus } from "../../eventBus";

    export default {
        name: 'GestioneLavori',
        components: { Tutti, Associati },
        data() {
            return {
                tab: 1
            }
        },
        created() {
            EventBus.$emit('updateTitleNavbar', 'Gestione lavori');
            EventBus.$on('changeTabGestione', (tab) => {
                this.tab = tab;
            });
        }
    }
</script>
