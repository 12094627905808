import axios from 'axios';
import config  from '@/config/config';
import { generalFunction } from '@/components/js/generalFunction';

export default {
    async getMyAdminUser(token) {
        return await axios.get(config.endpoint + "/api/get_my_admin?token="+token).then(response => {
            if (!response.data) {
                let token = generalFunction.getToken();
                if (token) generalFunction.setCookie('tokenofficina', '', 0);
            }
            return response.data;
        });
    },
    async getOperatote(token, params) {
        return await axios.get(config.endpoint + "/api/get_user_admin?token="+token+'&_id='+params).then(response => {
            return response.data;
        });
    },
    async aggiornaTessera(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio/aggiungi_punti_tessera?token="+token, params).then(response => {
            return response.data;
        });
    },
    async azzeraTessera(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio/rimuovi_punti_tessera?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getUserClient(token, params) {
        return await axios.get(config.endpoint + "/api/get_user_client?token="+token+"&search=_id,"+params).then(response => {
            return response.data;
        });
    },
    async logout(token) {
        return await axios.post(config.endpoint + "/api/logout_admin?token="+token).then(response => {
            return response.data;
        });
    },
}