<template>
    <v-row class="filters pr-4">
        <v-simple-table style="margin-top: 10px">
            <thead>
            <tr>
                <th class="order"/>
                <th class="text-left">Data di Presa</th>
                <th class="text-left">Tipo di presa</th>
                <th class="text-left">Operatore Presa</th>
                <th class="text-left">Data Consegna</th>
                <th class="text-left"></th>
                <th class="text-left">Stato presa</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td class="order">
                    <v-card class="order-numer">
                        <h6>{{ numeroPresa }}</h6>
                    </v-card>
                </td>
                <td>
                    <v-menu v-model="calendarDataPresa"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="viewDataPresa" prepend-icon="mdi-calendar" readonly v-on="on"/>
                        </template>
                        <v-date-picker locale="it" v-model="dataPresa" @input="changeDataPresa"/>
                    </v-menu>
                </td>
                <td>
                    <v-select outlined
                              v-model="selectedTipoPesa"
                              :items="tipiDiPresa"
                              item-text="name"
                              item-value="value" />
                </td>
                <td>
                    <v-select outlined
                              v-model="selectedOperatorePresa"
                              :items="operatoriPresa"
                              item-text="rName"
                              item-value="_id" />
                </td>
                <td>
                    <v-menu v-model="calendarDataConsegna"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="viewDataConsegna" prepend-icon="mdi-calendar" readonly v-on="on"/>
                        </template>
                        <v-date-picker locale="it" v-model="dataConsegna" @input="changeDataConsegna"/>
                    </v-menu>
                </td>
                <td>
                    <v-dialog v-model="modalCalendar" width="500" >
                        <template v-slot:activator="{ on }">
                            <v-btn color="red lighten-2" dark v-on="on">
                                <i class="fas fa-calendar-check"></i>
                            </v-btn>
                        </template>
                        <Associati :days="15" style="background-color: white; padding: 10px"/>
                    </v-dialog>
                </td>
                <td>
                    <v-select outlined
                              class="active-dropdown stato-presa"
                              v-model="selectedStatoPesa"
                              :items="statiPresa"
                              item-text="name"
                              item-value="value" />
                </td>
            </tr>
            </tbody>
        </v-simple-table>
    </v-row>
</template>
<script>
    import {
        momentYMD,
        momentLL
    } from "@/components/js/formatDate";
    import Associati from '@/components/gestione/associati/Associati';
    import { generalFunction } from "@/components/js/generalFunction";

    export default {
        name: 'HeadLavorazione',
        props: ['presa'],
        components: {
            Associati
        },
        data() {
            return {
                numeroPresa: 'Nuova presa',
                dataPresa: momentYMD(new Date),
                viewDataPresa: momentLL(new Date),
                dataConsegna: undefined,
                viewDataConsegna: undefined,
                calendarDataPresa: false,
                calendarDataConsegna: false,
                tipiDiPresa: [
                    {
                        name: 'Bici Officina',
                        value: 0
                    }, {
                        name: 'Bici Negozio',
                        value: 1
                    }, {
                        name: 'Bici Online',
                        value: 2
                    }, {
                        name: 'Noleggio / Test',
                        value: 3
                    }, {
                        name: 'Esposizione',
                        value: 4
                    }
                ],
                selectedTipoPesa: 0,
                statiPresa: [
                    {
                        name: 'Aperta',
                        value: 1
                    }, {
                        name: 'Chiusa',
                        value: 2
                    }, {
                        name: 'Sospesa',
                        value: 3
                    }
                ],
                selectedStatoPesa: 1,
                operatoriPresa: [],
                selectedOperatorePresa: 0,
                modalCalendar: false
            }
        },
        async created() {
            let token = await generalFunction.getToken();
            if (token) {
                let operatori = await generalFunction.getOperatori(token);
                if (
                    operatori &&
                    operatori.length > 0
                ) {
                    for (let operatore of operatori) {
                        operatore.rName = '';
                        if (operatore.nome)
                            operatore.rName += operatore.nome + " ";
                        if (operatore.cognome)
                            operatore.rName += operatore.cognome;
                        if(operatore.token == token)
                            this.selectedOperatorePresa = operatore._id;
                    }
                    this.operatoriPresa = operatori;
                }
                this.operatoriPresa.push({  _id: 0, rName: 'Seleziona un operatore'});
            }
            this.controlEditPresa();
        },
        methods: {
            changeDataPresa() {
                this.calendarDataPresa = false;
                this.viewDataPresa = momentLL(this.dataPresa);
            },
            changeDataConsegna() {
                this.calendarDataConsegna = false;
                this.viewDataConsegna = momentLL(this.dataConsegna);
            },
            controlEditPresa() {
                if (
                    this.presa &&
                    Object.entries(this.presa).length > 0
                ) {
                    if (this.presa.numero)
                        this.numeroPresa = '# '+this.presa.numero;

                    if (this.presa.data_presa) {
                        this.dataPresa = momentYMD(this.presa.data_presa);
                        this.viewDataPresa = momentLL(this.presa.data_presa);
                    }

                    if (this.presa.data_consegna) {
                        this.dataConsegna = momentYMD(this.presa.data_consegna);
                        this.viewDataConsegna = momentLL(this.presa.data_consegna);
                    }

                    if (this.presa.operatore_presa)
                        this.selectedOperatorePresa = this.presa.operatore_presa;

                    if (this.presa.tipo !== undefined)
                        this.selectedTipoPesa = this.presa.tipo;

                    if (this.presa.stato)
                        this.selectedStatoPesa = this.presa.stato;
                }
            }
        },
        watch: {
            presa: async function () {
                this.controlEditPresa();
            }
        }
    }
</script>
