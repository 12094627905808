export const cleaner = (data ,extensions = {}, options=cleaner.options) => {
    // to exclude standard options pass as third argument
    // {coerce:[],accept:[],reject:[],escape:[],eval:false}

    // merge extensions with options
    options = Object.keys(options).reduce((accum,key) => {
        if(Array.isArray(options[key])) { // use union of arrays
            accum[key] = (extensions[key]||[]).reduce((accum,item) => {
                accum.includes(item) || accum.push(item); return accum;
            },options[key].slice());
        } else if(typeof(extensions[key])==="undefined") {
            accum[key] = options[key];
        } else {
            accum[key] = extensions[key];
        }
        return accum;
    },{});
    // data may be safe if coerced into a proper format
    data = options.coerce.reduce((accum,coercer) =>
        coercer(accum),data);
    //these are always safe
    if(options.accept.some(test => test(data))) return data;
    //these are always unsafe
    if(options.reject.some(test => test(data))) return;
    //remove unsafe data from arrays
    if(Array.isArray(data)) {
        data.forEach((item,i) => data[i] = cleaner(data));
        return data;
    }
    //recursively clean data on objects
    if(data && typeof(data)==="object") {
        for(let key in data) {
            const cleaned = cleaner(data[key]);
            if(typeof(cleaned)==="undefined") {
                delete data[key];
            } else {
                data[key] = cleaned;
            }
        }
        return data;
    }
    if(typeof(data)==="string") {
        data = options.escape.reduce((accum,escaper) =>
            escaper(accum),data); // escape the data
        if(options.eval) {
            try {
                // if data can be converted into something that is legal
                // JavaScript, clean it, make sure that options.reject has
                // already removed undesireable self evaluating or blocking
                // functions. Call with null to block global access.
                return cleaner(Function("return " + data).call(null));
            } catch(error) {
                // otherwise, just return it
                return data;
            }
        }
    }
    return data;
};
// statically merge extensions into default options
cleaner.extend = (extensions) => {
    const options = cleaner.options;
    cleaner.options = Object.keys(options).reduce((accum,key) =>
    {
        if(Array.isArray(options[key])) { // use union of arrays
            accum[key] = (extensions[key]||[]).reduce((accum,item) => {
                accum.includes(item) || accum.push(item); return accum;
            },options[key].slice());
        } else if(typeof(extensions[key])==="undefined") {
            accum[key] = options[key];
        } else {
            accum[key] = extensions[key];
        }
        return accum;
    },{});
};
// default options/support for coerce, accept, reject, escape, eva
cleaner.options = {
    coerce: [],
    accept: [data => !data ||
        ["number","boolean"].includes(typeof(data))],
    reject: [
        // executable data
        data => typeof(data)==="function",
        // possible server execution like <?php
        data => typeof(data)==="string" && data.match(/<\s*\?\s*.*\s*/),
        // direct eval, might block or negatively impact cleaner itself,
        data => typeof(data)==="string" &&
            data.match(/eval|alert|prompt|dialog|void|cleaner\s*\(/),
        // very suspicious,
        data => typeof(data)==="string" && data.match(/url\s*\(/),
        // might inject nastiness into logs,
        data => typeof(data)==="string" &&
            data.match(/console\.\s*.*\s*\(/),
        // contains javascript,
        data => typeof(data)==="string" && data.match(/javascript:/),
        // arrow function
        data => typeof(data)==="string" &&
            data.match(/\(\s*.*\s*\)\s*.*\s*=>/),
        // self eval, might negatively impact cleaner itself
        data => typeof(data)==="string" &&
            data.match(/[Ff]unction\s*.*\s*\(\s*.*\s*\)\s*.*\s*\{\s*.*\s*\}\s*.*\s*\)\s*.*\s*\(\s*.*\s*\)/),
    ],
    escape: [
        data => { // handle possible query strings
            if(typeof(data)==="string" && data[0]==="?") {
                const parts = data.split("&");
                let max = parts.length;
                return parts.reduce((accum,part,i) => {
                    const [key,value] = decodeURIComponent(part).split("="),
                        type = typeof(value),
                        // if type undefined, then may not even be URL query
                        // string, so clean "key"
                        cleaned = (type!=="undefined"
                            ? cleaner(value)
                            : cleaner(key));
                    if(typeof(cleaned)!=="undefined") {
                        // keep only those parts of query string that are clean
                        accum += (type!=="undefined"
                            ? `${key}=${cleaned}`
                            : cleaned) + (i<max-1 ? "&" : "");
                    } else {
                        max--;
                    }
                    return accum;
                },"?");
            }
            return data;
        },
        data => { // handle escaping html entities
            if(typeof(data)==="string" && data[0]!=="?"
                && typeof(document)!=="undefined") {
                // on client or a server DOM is operable
                const div = document.createElement('div');
                div.appendChild(document.createTextNode(data));
                return div.innerHTML;
            }
            return data;
        }
    ],
    eval: true
};