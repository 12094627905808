<template>
    <div>
        <div class="label">
            <label>Aggiungi una nuova bici</label>
        </div>
        <div>
            <v-form ref="formAddUserBike" v-model="valid" lazy-validation>
                <v-row>
                    <v-col sm="6">
                        <v-text-field v-model="modello" :rules="textRule" label="Modello" required/>
                    </v-col>
                    <v-col sm="6">
                        <v-text-field v-model="numeroTelaio" label="Nr. Telaio" />
                    </v-col>
                    <v-col sm="6">
                        <label style="font-size: 12px !important;" @click="initBrands">
                            <i class="fas fa-sync-alt" style="font-size: 12px !important;"></i> ricarica
                        </label>
                        <label style="float: right; margin-bottom: 1px; font-size: 12px !important;" @click="creaMarcaRapida">
                            <i class="fas fa-plus-circle" style="font-size: 12px !important;"></i> crea
                        </label>
                        <v-select v-model="marca"
                                  label="Marca"
                                  :items="brands"
                                  item-text="rName"
                                  item-value="_id"
                                  style="width: 100%;"/>
                    </v-col>
                    <v-col sm="4">
                        <v-select v-model="tipo"
                                  :rules="selectRule"
                                  label="Tipo"
                                  :items="bikeTypes"
                                  item-text="name"
                                  item-value="value"
                                  required />
                    </v-col>
                    <v-col sm="2">
                        <v-checkbox v-model="ebike" label="E-Bike" />
                    </v-col>
                    <v-col sm="6">
                        <v-menu v-model="calendarDataAcquisto"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="viewDataAcquisto" prepend-icon="mdi-calendar" readonly v-on="on"/>
                            </template>
                            <v-date-picker label="Data di acquisto" locale="it" v-model="dataAcquisto" @input="changeDataAcquisto"/>
                        </v-menu>
                    </v-col>
                    <v-col sm="4">
                        <v-text-field v-model="dealer" label="Dealer" />
                    </v-col>
                    <v-col sm="2">
                        <v-checkbox v-model="dealerCiclimattio" label="Dealer ciclimattio" />
                    </v-col>
                </v-row>
                <!--v-textarea v-model="note" label="Note" /-->
                <v-btn color="secondary mt-2 mr-2" @click="$emit('closeModalBike')">Annulla</v-btn>
                <v-btn color="primary mt-2" :disabled="!valid" @click="addUserBike">Aggiungi</v-btn>
            </v-form>
        </div>
    </div>
</template>
<script>
import {
    momentYMD,
    momentLL
} from "@/components/js/formatDate";
import config from "@/config/config";
import apiUser from '@/components/Presa/cliente/js/user'
import { validationRules } from "@/components/js/validationRules";
import { generalFunction } from "@/components/js/generalFunction";

export default {
    name: 'AggiungiBici',
    props: ['idUser'],
    data() {
        return {
            valid: true,
            modello: undefined,
            numeroTelaio: undefined,
            marca: undefined,
            brands: [],
            tipo: undefined,
            bikeTypes: [
                { value: 'mtb', name: 'Mtb'},
                { value: 'strada', name: 'Strada'},
                { value: 'city', name: 'City'},
                { value: 'gravel', name: 'Gravel'},
            ],
            ebike: false,
            dealer: undefined,
            dealerCiclimattio: false,
            note: undefined,
            selectRule: validationRules.selectRule(),
            textRule: validationRules.textRule(),
            dataAcquisto: undefined,
            viewDataAcquisto: 'Data di acquisto',
            calendarDataAcquisto: false,
        }
    },
    async created() {
        await this.initBrands();
    },
    methods: {
        async initBrands() {
            let brands = [] = await generalFunction.getBrands();
            if (brands[1]) {
                for (let brand of brands[1]) {
                    brand.rName = 'Senza Nome';
                    if (
                        brand &&
                        brand.it &&
                        brand.it.nome
                    ) brand.rName = brand.it.nome;
                }
            }
            this.brands = brands[1];
        },
        changeDataAcquisto() {
            this.calendarDataAcquisto = false;
            this.viewDataAcquisto = momentLL(this.dataAcquisto);
        },
        async addUserBike() {
            if (this.$refs.formAddUserBike.validate()) {
                let token = generalFunction.getToken();
                if (token && this.idUser) {
                    let data = {
                        id_utente: this.idUser,
                        modello: this.modello,
                        numeroTelaio: this.numeroTelaio,
                        marca: this.marca,
                        tipo: this.tipo,
                        ebike: this.ebike,
                        dealer: this.dealer,
                        dealerCiclimattio: this.dealerCiclimattio,
                        dataAcquisto: this.dataAcquisto,
                        note: this.note,
                    };
                    let bike = await apiUser.addUserBike(token, data);
                    if (bike) {
                        this.$emit('closeModalBike');
                        this.modello = undefined;
                        this.numeroTelaio = undefined;
                        this.marca = undefined;
                        this.tipo = undefined;
                        this.ebike = false;
                        this.dealer = undefined;
                        this.dealerCiclimattio = false;
                        this.dataAcquisto = momentYMD(new Date);
                        this.note = undefined;
                    }
                    else alert('Spiacenti si è verificato un errore');
                } else alert('Spiacenti si è verificato un errore');
            }
        },
        async creaMarcaRapida() {
            let token = generalFunction.getToken();
            let data = {
                id_attributo: config.id_marche
            }
            let newBrand = await apiUser.addNewBrand(token, data);
            if (newBrand)
                window.open(config.domainAdmin + '/admin/attributi/?_id='+config.id_marche+'&id_value='+newBrand)
            else alert('Spiacenti si è verificato un errore!');
        }
    }
}
</script>
