let endpoint, domainAdmin, domainCookie, id_reparto_officina = '', id_lavorazioni = '', id_marche = '';

let project_status = 'enviroment';  /* development - enviroment */
if(project_status === 'development') {
    endpoint = "http://localhost:5000";
    domainCookie = "/";
    domainAdmin = "http://kreta.local";
    id_reparto_officina = "61f8e4e3376cf213d0f46a08";
    id_lavorazioni = "5e255c53d291b53d6c511161";
    id_marche = "5e255c39d291b53d6c51115f";
} else if (project_status === 'enviroment'){
    endpoint = "https://api.ciclimattio.com";
    domainCookie = "/";
    domainAdmin = "https://kreta.ciclimattio.com";
    id_reparto_officina = "5e297b4cd31154076d156c06";
    id_lavorazioni = "5e255c53d291b53d6c511161";
    id_marche = "5e255c39d291b53d6c51115f";
}

export default {
    project_status: project_status,
    endpoint: endpoint,
    domainAdmin: domainAdmin,
    domainCookie: domainCookie,
    id_reparto_officina: id_reparto_officina,
    id_lavorazioni: id_lavorazioni,
    id_marche: id_marche,
    default_lang: 'it',
}
