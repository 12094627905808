import axios from 'axios';
import config from '@/config/config';

export const generalFunction = {
    setCookie: function (cname, cvalue, exdays){
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + " ;path="+config.domainCookie;
    },
    getCookie: function (cname) {
        const name = cname + "=";
        const values = document.cookie.split(';');
        for(let value of values) {
            while (value.charAt(0) === ' ') value = value.substring(1);
            if (value.indexOf(name) === 0) return value.substring(name.length, value.length);
        }
        return "";
    },
    getToken: function () {
        return this.getCookie('tokenofficina');
    },
    controlLogin: async function (token) {
        return await axios.get(config.endpoint + '/api/control_login?token='+token).then(response => {
            return response.data;
        }).catch(() => {
            return false;
        });
    },
    async getOperatori(token) {
        return await axios.post(config.endpoint + "/api/get_utenti_reparto?token="+ token, { id_reparto: config.id_reparto_officina }).then(response => {
            return response.data;
        });
    },
    async getBrands() {
        return await axios.get(config.endpoint + "/api/get_marche_admin").then(response => {
            return response.data;
        });
    },
    async getCategorie() {
        return await axios.get(config.endpoint + "/api/get_categorie_array").then(response => {
            return response.data;
        });
    },
    sumHours(stringa1, stringa2, split) {
        let rt1 = 0;
        stringa1 = String(stringa1).split(split);
        let stringa_hours = Number(stringa1[0]);
        let stringa_minutes = Number(stringa1[1]);

        stringa2 = String(stringa2).split(split);
        let ore_hours = Number(stringa2[0]);
        let ore_minutes = Number(stringa2[1]);

        if (stringa_hours === 0) rt1 = stringa_minutes;
        else rt1 = (stringa_hours * 60) + stringa_minutes;
        let rt3 = rt1 + ((ore_hours * 60) + ore_minutes);
        let totale_ore = Math.floor(rt3 / 60);
        let totale_minuti = rt3 - (totale_ore * 60);
        return totale_ore +":"+('0' + totale_minuti).slice(-2);
    },
    addDays(date, days) {
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + days);
        return copy
    },
};
