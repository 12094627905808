<template>
    <v-app>
        <v-content>
            <Navbar v-if="!$route.meta.hideNavigation" :titleNavbar="titleNavbar" :operatoreNavbar="operatoreNavbar" />
            <router-view/>
        </v-content>
    </v-app>
</template>
<script>
    import Navbar from '@/components/Navbar.vue';
    import { EventBus } from "./eventBus";

    export default {
        name: 'App',
        components: { Navbar },
        data() {
            return {
                titleNavbar: 'Dashboard',
                operatoreNavbar: undefined
            }
        },
        created() {
            EventBus.$on('updateTitleNavbar', (data, idOperatore) => {
                if (data) this.titleNavbar = data;
                else this.titleNavbar = 'Dashboard';

                this.operatoreNavbar = idOperatore;
            });
        }
    };
</script>
