import axios from 'axios';
import config  from '@/config/config';

export default {
    async addNewPresa(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_nuova_presa?token="+token, params).then(response => {
            return response.data;
        });
    },
    async updatePresa(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_aggiorna_presa?token="+token, params).then(response => {
            return response.data;
        });
    },
    async updateStatoGaranzia(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_aggiorna_stato_garanzia?token="+token, params).then(response => {
            return response.data;
        });
    },
    async updateNoteGaranzia(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_aggiorna_note_garanzia?token="+token, params).then(response => {
            return response.data;
        });
    },
    async removeArticlePresa(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_rimuovi_articolo_presa?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getPresa(token, params) {
        return await axios.get(config.endpoint + "/api/ciclimattio_get_presa?token="+token+"&search=_id,"+params).then(response => {
            return response.data;
        });
    },
    async getPresaWithDetails(token, params) {
        return await axios.get(config.endpoint + "/api/ciclimattio_get_presa_detail?token="+token+"&id_presa="+params).then(response => {
            return response.data;
        });
    },
    async searchPrese(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_search_prese?token="+token, params).then(response => {
            return response.data;
        });
    },
    async searchGaranzie(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_search_garanzie?token="+token, params).then(response => {
            return response.data;
        });
    },
    async esportaPrese(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio/officina/esporta_prese?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getPreseAssociate(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_get_prese_associate?token="+token, params).then(response => {
            return response.data;
        });
    },
    async assegnaOperatore(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_assegna_operatore_presa?token="+token, params).then(response => {
            return response.data;
        });
    },
    async completaPresa(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_completa_presa?token="+token, params).then(response => {
            return response.data;
        });
    },
    async presaSospesa(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_presa_sospesa?token="+token, params).then(response => {
            return response.data;
        });
    },
    async consegnaPresa(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_consegnata_presa?token="+token, params).then(response => {
            return response.data;
        });
    },
    async pagamentoPresa(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_pagamento_presa?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deletePresa(token, params) {
        return await axios.post(config.endpoint + "/api/ciclimattio_elimina_presa?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getProdotti(search_params) {
        return await axios.get(config.endpoint + "/api/search_optimized_product_rule_unwind?"+search_params.toString()).then(response => {
            return response.data;
        });
    },
    async salvaNoteTempo(token, data) {
        return await axios.post(config.endpoint + "/api/ciclimattio_salva_note_e_tempo_presa?token="+token, data).then(response => {
            return response.data;
        });
    },
    async salvaNumeroTelaio(token, data) {
        return await axios.post(config.endpoint + "/api/ciclimattio/salva_numero_telaio?token="+token, data).then(response => {
            return response.data;
        });
    },
    async aggiornaProdottoOrdine(token, data) {
        return await axios.post(config.endpoint + "/api/ciclimattio/officina/aggiorna_prodotto_ordine?token="+token, data).then(response => {
            return response.data;
        });
    },
    async creaOrdinePresa(token, data) {
        return await axios.post(config.endpoint + "/api/ciclimattio_crea_ordine_presa?token="+token, data).then(response => {
            return response.data;
        });
    },
    async creaOrdineOfficina(token, data) {
        return await axios.post(config.endpoint + "/api/ciclimattio/cassa/inizializza_ordine_officina?token="+token, data).then(response => {
            return response.data;
        });
    },
    async assegnaOrdinePresa(token, data) {
        return await axios.post(config.endpoint + "/api/ciclimattio/prese/assegna_ordine?token="+token, data).then(response => {
            return response.data;
        });
    },
    async getNumeroNonAssegnate(token) {
        return await axios.get(config.endpoint + "/api/ciclimattio_get_numero_prese_non_assegnate?token="+token).then(response => {
            return response.data;
        });
    },
    async searchOrders(params) {
        return await axios.get(config.endpoint + "/api/dynamic_search?" + params).then(response => {
            return response.data;
        });
    }
}
