<template>
	<v-tab-item class="raccolta">
		<v-simple-table class="table-raccolta">
			<thead>
			<tr>
				<th class="text-left">Numero ordine</th>
				<th class="text-left">Data</th>
				<th class="text-left">Totale</th>
				<th class="text-left">Stato</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="ordine in ordiniOfficina" :key="ordine.rId" @click="goToOrder(ordine.rId)">
				<td>#{{ ordine.numero }}</td>
				<td>{{ ordine.data }}</td>
				<td>{{ ordine.totale }}</td>
				<td>
					<v-badge :color="ordine.colore_stato" :content="ordine.stato" />
				</td>
			</tr>
			</tbody>
		</v-simple-table>
		<hr>
		<v-row>
			<v-col cols="6"></v-col>
			<v-col cols="6">
				<v-btn class="mt-2" color="primary" @click="creaOrdineOfficina" style="float: right">
					<v-icon class="mr-2">mdi-plus-circle</v-icon>
					Aggiungi ordine
				</v-btn>
				<v-btn class="mt-2 mr-2" color="primary" style="float: right" @click="viewAssignOrder = !viewAssignOrder">
					<v-icon class="mr-2">mdi-magnify</v-icon>
				</v-btn>
			</v-col>
			<v-col cols="6"></v-col>
			<v-col v-if="viewAssignOrder" cols="6">
				<div class="label">
					<label>Seleziona un ordine</label>
				</div>
				<v-autocomplete label="Cerca numero ordine"
								:search-input.sync="searchOrder"
								:loading="loadingSearchOrder"
								:items="listOrders"
								item-text="rText"
								hide-no-data
								return-object
								no-filter
								prepend-icon="mdi-magnify"
								v-model="selectedOrder"
								@change="assegnaOrdine()" />
			</v-col>
		</v-row>
	</v-tab-item>
</template>
<script>
import apiPresa from '@/components/Presa/js/presa';
import { generalFunction } from "@/components/js/generalFunction";
import config from "@/config/config";
import { momentLL } from "@/components/js/formatDate";
let timeoutRicercaOrdine = null;

export default {
	name: 'Raccolta',
	props: ['presa'],
	data() {
		return {
			id_presa: this.$route.query._id,
			ordiniOfficina: [],
			searchOrder: undefined,
			listOrders: [],
			loadingSearchOrder: false,
			selectedOrder: {},
			viewAssignOrder: false
		}
	},
	computed: {
		rConfig() {
			return config;
		}
	},
	watch: {
		presa() {
			this.controlEditPresa();
		},
		async searchOrder(search) {
			clearTimeout(timeoutRicercaOrdine);
			let those = this;
			timeoutRicercaOrdine = setTimeout(function () {
				those.startSearchOrder(search);
			}, 500);
		},
	},
	async created() {
		await this.controlEditPresa();
	},
	methods: {
		async initPresa() {
			let token = await generalFunction.getToken();
			if (token) {
				let presa = await apiPresa.getPresa(token, this.id_presa);
				if (
					presa &&
					Object.entries(presa).length > 0
				) this.presa = { ...presa };
			}
		},
		async creaOrdineOfficina() {
			let token = await generalFunction.getToken();
			if (this.id_presa) {
				let data = {
					id_presa: this.$route.query._id
				}
				let ordine = await apiPresa.creaOrdineOfficina(token, data);
				if (
					ordine &&
					ordine.id_ordine
				) location.href = this.rConfig.domainAdmin + '/admin/ordini/cassa_custom/?_id='+ordine.id_ordine+'&ordine_cassa=true';
				else alert('Spiacenti si è verificato un errore!');
			} else alert('Prima di procedere salva la presa!');
		},
		controlEditPresa() {
			let ordiniOfficina = [];
			if (
				this.presa &&
				this.presa.ordiniOfficina &&
				this.presa.ordiniOfficina.length
			) {
				for (let order of this.presa.ordiniOfficina) {
					let rNumero = '';
					let rDate = '';
					let rTotale = '';
					let rStato = '';
					let rColoreStato = '';
					if (order.numero) rNumero = order.numero;
					if (order.totale) rTotale = order.totale + ' €';
					if (order.data_creazione) rDate = momentLL(order.data_creazione);
					if (
						order.stato_ordine &&
						order.full_stato &&
						order.full_stato.length
					) {
						for (let stato of order.full_stato) {
							if (
								stato.it &&
								stato.it.nome &&
								String(stato._id) === String(order.stato_ordine)
							) {
								rStato = stato.it.nome;
								if (stato.colore) rColoreStato = stato.colore;
							}
						}
					}
					ordiniOfficina.push({
						rId: order._id,
						numero: rNumero,
						data: rDate,
						totale: rTotale,
						stato: rStato,
						colore_stato: rColoreStato
					})
				}
			}

			this.ordiniOfficina = ordiniOfficina;
		},
		goToOrder(idOrdine) {
			if (idOrdine) window.open(this.rConfig.domainAdmin + '/admin/ordini/?_id='+idOrdine);
			else alert('Spiacenti si è verificato un errore!');
		},
		async startSearchOrder(search) {
			if (search) {
				search = String(search).split(' ').join('|');
				if (this.loadingSearchOrder) return;
				this.loadingSearchOrder = true;
				let token = await generalFunction.getToken();
				if (
					search &&
					token
				) {
					let data = '';
					data += "&token=" + token;
					data += "&table=ordini";
					data += "&cerca=" + search;
					data += "&page=1";
					data += "&page_size=20";
					let orders = await apiPresa.searchOrders(data);
					if (
						orders &&
						orders[0] &&
						orders[0].length
					) {
						for (let order of orders[0]) {
							if (
								!order.bozza &&
								!order.archiviato
							) {
								order.rText = '';

								if (order.numero) order.rText = order.numero;
								else if (
									order.reso &&
									order.numero_reso
								) order.rText = 'R: ' + order.numero_reso;
								else if (
									order.preventivo &&
									order.numero_preventivo
								) order.rText = 'P: ' + order.numero_preventivo;
								else if (
									order.cassa &&
									order.numero_cassa
								) order.rText = 'C: ' + order.numero_cassa;

								if (
									order &&
									order.utente &&
									order.utente.email
								) order.rText += ' - ' + order.utente.email;

								order.rText = String(order.rText);
							}
						}
						this.listOrders = orders[0];
					}
				}
				this.loadingSearchOrder = false;
			}
		},
		async assegnaOrdine() {
			if(!confirm("Sicuro di voler associare questo ordine?"))
				return;

			if (
				this.selectedOrder &&
				this.selectedOrder._id
			) {
				let token = await generalFunction.getToken();
				let data = {
					id_presa: this.id_presa,
					id_ordine: this.selectedOrder._id
				};
				let orderAssign = await apiPresa.assegnaOrdinePresa(token, data);
				if (orderAssign) {
					this.viewAssignOrder = true;
					this.initPresa();
				} else alert('Spiacenti si è verificato un errore!');
			}
		}
	}
}
</script>
