import Vue from "vue";
import "../assets/styles/style.scss";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#222",
        secondary: "#777",
        accent: "#777",
        success: "#222"
      }
    }
  }
});
