<template>
    <div class="gestisci-lavorazioni">
        <v-container fluid pr-4 pl-4>
            <div class="filters-presa">
                <!--v-row>
                    <v-col sm='12' class="gestione-filters">
                        <div class="text-right pb-4 pr-3" @click="goRouterBack">
                            <i class="far fa-times-circle close"/>
                        </div>
                        <v-simple-table>
                            <thead>
                            <tr>
                                <th class="text-left">Presa</th>
                                <th class="text-left">Operatore</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td style="border-bottom: unset">
                                    <v-select outlined
                                              v-model="selectedStatoPesa"
                                              :items="statiPresa"
                                              item-text="name"
                                              item-value="value"
                                              @change="addToUrl('stato', selectedStatoPesa)"/>
                                </td>
                                <td class="text-center" style="width: 70px; border-bottom: unset;" @click="resetFilter">
                                    <i style="color: #222;" class="fas fa-sync"></i>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row -->
                <v-simple-table class="content-table table-lavorazioni">
                    <thead>
                    <tr>
                        <th class="text-left" />
                        <th class="text-left">Ordine</th>
                        <th class="text-left">Cliente</th>
                        <th class="text-left">Bici</th>
                        <th class="text-left">Fornitore garanzia</th>
                        <th class="text-left">Stato</th>
                        <th class="text-left">Note</th>
                        <th class="text-left"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="garanzia in garanzie" :key="garanzia._id">
                        <td @click="$router.push({ path: '/presa', query: { _id: garanzia._id }})">
                            <b># {{ garanzia.numero }}</b>
                            <br>
                            <small v-if="garanzia.tipo === 0 || !garanzia.tipo">Bici Officina</small>
                            <small v-else-if="garanzia.tipo === 1">Bici Negozio</small>
                            <small v-else-if="garanzia.tipo === 2">Bici Online</small>
                            <small v-else-if="garanzia.tipo === 3">Noleggio / Test</small>
                            <small v-else-if="garanzia.tipo === 4">Esposizione</small>
                        </td>
                        <td>
                        <span v-if="garanzia.id_ordine && garanzia.ordine && garanzia.ordine[0] && garanzia.ordine[0].numero">
                           <span v-if="garanzia.ordine[0].spedizione_premium">
                                <i class="fas fa-crown" style="color: #c29436"></i>
                           </span>
                             <span v-if="garanzia.ordine[0].dettagli_ordine && garanzia.ordine[0].dettagli_ordine.montaggio_prodotti" class="ml-2" data-tooltip title="Montaggio premium">
                               <i class="fas fa-puzzle-piece"></i>
                           </span>
                            # {{ garanzia.ordine[0].numero }}
                        </span>
                        </td>
                        <td @click="$router.push({ path: '/presa', query: { _id: garanzia._id }})"><b>{{ garanzia.rNameUser }}</b></td>
                        <td @click="$router.push({ path: '/presa', query: { _id: garanzia._id }})"><b>{{ garanzia.rNameBike }}</b></td>
                        <td @click="$router.push({ path: '/presa', query: { _id: garanzia._id }})"><b>{{ garanzia.rFornitoreGaranzia }}</b></td>
                        <td style="max-width: 150px">
                            <v-select outlined
                                      v-model="garanzia.stato_garanzia"
                                      :items="statiGaranzia"
                                      item-text="name"
                                      item-value="value"
                                      @change="updateStatoGaranzia(garanzia._id, garanzia.stato_garanzia)"/>
                        </td>
                        <td style="max-width: 200px">
                            <v-textarea v-model="garanzia.note_garanzia" rows="2" solo />
                        </td>
                        <td>
                            <i v-if="!garanzia.loading" @click="salvaNoteGaranzia(garanzia, garanzia.note_garanzia)" class="fas fa-save" style="color: #222"></i>
                            <i v-if="garanzia.loading" class="fas fa-circle-notch fa-spin" style="color: #222"></i>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
                <v-row>
                    <v-col sm="4"/>
                    <v-col sm="4">
                        <v-pagination v-model="page" @input="addToUrl('page', page)" :length="lengthPagination" circle />
                    </v-col>
                    <v-col sm="4"/>
                </v-row>
            </div>
        </v-container>
    </div>
</template>
<script>
import { EventBus } from "@/eventBus";
import { generalFunction } from "@/components/js/generalFunction";
import apiPresa from "@/components/Presa/js/presa";
import { momentDate } from "@/components/js/formatDate";

export default {
    name: 'Garanzie',
    data() {
        return {
            page: 1,
            pageSize: 20,
            lengthPagination: 0,
            garanzie: [],
            statiGaranzia: [
                {
                    name: 'Nuova',
                    value: 1,
                }, {
                    name: 'Inviata al fornitore',
                    value: 2,
                }, {
                    name: 'Rientrata',
                    value: 3
                }, {
                    name: 'Annullata',
                    value: 4
                }
            ],
        }
    },
    async created() {
        let brands = [] = await generalFunction.getBrands();
        if (brands[1]) {
            for (let brand of brands[1]) {
                brand.rName = 'Senza Nome';
                if (
                    brand &&
                    brand.it &&
                    brand.it.nome
                ) brand.rName = brand.it.nome;
            }
        }
        this.brands = brands[1];

        EventBus.$emit('updateTitleNavbar', 'Garanzie');
        this.initSearchGaranzie()
    },
    methods: {
        goRouterBack() {
            this.$router.back();
        },
        async resetFilter() {
            await this.$router.push({ path: '/garanzie', query: Object.assign({}, {})}).catch(() => {});
            await this.initSearchGaranzie();
        },
        async addToUrl(params, value) {
            await this.$router.push({ path: '/garanzie', query: Object.assign({}, this.$route.query,{ [params]: value } )});
            await this.initSearchGaranzie();
        },
        async initSearchGaranzie() {
            this.lengthPagination = 0;
            this.listPrese = [];

            let token = await generalFunction.getToken();
            if (token) {
                let data = {
                    page: this.page,
                    page_size: this.pageSize
                };

                if (this.$route.query) {
                    //if (this.$route.query.stato)
                    //    data.stato = this.$route.query.stato;
                }

                let garanzie = await apiPresa.searchGaranzie(token, data);
                if (garanzie) {
                    if (garanzie[0]) {
                        for (let garanzia of garanzie[0]) {
                            garanzia.rNameUser = '';
                            garanzia.rNameBike = '';
                            garanzia.loading = false;

                            if (!garanzia.stato_garanzia)
                                garanzia.stato_garanzia = 1;

                            if (
                                garanzia.user &&
                                garanzia.user[0]
                            ) {
                                if (garanzia.user[0].nome)
                                    garanzia.rNameUser += garanzia.user[0].nome;

                                if (garanzia.user[0].cognome)
                                    garanzia.rNameUser += ' ' + garanzia.user[0].cognome;

                                if (
                                    garanzia.id_bici &&
                                    garanzia.user[0].dettagli_utente &&
                                    garanzia.user[0].dettagli_utente.bici
                                ) {
                                    let bike = garanzia.user[0].dettagli_utente.bici.find(bike => {
                                        return bike._id === garanzia.id_bici;
                                    });

                                    if(
                                        bike &&
                                        bike['marca']
                                    ) {
                                        let rMarca = this.brands.find(brand => {
                                            return brand._id === bike['marca'];
                                        });
                                        if(rMarca)
                                            bike['rMarca'] = rMarca['rName'];
                                    }

                                    if(
                                        garanzia &&
                                        garanzia['fornitore_garanzia']
                                    ) {
                                        let rMarca = this.brands.find(brand => {
                                            return brand._id === garanzia['fornitore_garanzia'];
                                        });
                                        if(rMarca)
                                            garanzia['rFornitoreGaranzia'] = rMarca['rName'];
                                    }

                                    if (
                                        bike &&
                                        bike.modello
                                    ) garanzia.rNameBike = (bike.rMarca ? (bike.rMarca + ' ') : '') + bike.modello;
                                }
                            }

                            if (garanzia.data_presa)
                                garanzia.viewDataPresa = momentDate(garanzia.data_presa);
                        }
                        this.garanzie = garanzie[0];
                    }
                    if (garanzie[1])
                        this.lengthPagination = Math.ceil(garanzie[1] / this.pageSize);
                }
            }
        },
        async updateStatoGaranzia(id_presa, stato) {
            let token = await generalFunction.getToken();
            if (token) {
                let data = {
                    id_presa: id_presa,
                    stato_garanzia: stato
                }
                await apiPresa.updateStatoGaranzia(token, data);
            }
        },
        async salvaNoteGaranzia(garanzia, note) {
            let token = await generalFunction.getToken();
            if (token) {
                garanzia.loading = true;
                let data = {
                    id_presa: garanzia._id,
                    note_garanzia: note
                }
                await apiPresa.updateNoteGaranzia(token, data);
                garanzia.loading = false;
            }
        }
    }
}
</script>
