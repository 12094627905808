<template>
    <v-container fluid>
        <v-layout row>
            <v-col sm="12" class="gestione-filters fixed-width">
                <v-simple-table>
                    <thead>
                    <tr>
                        <th class="text-left">Data Specifica</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <v-menu v-model="calendarData"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="viewData" prepend-icon="mdi-calendar" readonly v-on="on"/>
                                </template>
                                <v-date-picker v-model="data" @input="changeData"/>
                            </v-menu>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-layout>
        <v-simple-table class="content-table associati" v-for="(date, index) in listDate" :key="index">
            <caption class="text-left">{{ date.viewData }}</caption>
            <thead>
            <tr>
                <th class="text-left">Operatore</th>
                <th class="text-left">Eventi</th>
                <th class="text-left">Tempo stimato</th>
                <th class="text-left"/>
            </tr>
            </thead>
            <tbody>
            <tr v-for="operatore in date.operatori" :key="operatore._id">
                <td><i class="fas fa-circle" style="font-size: 14px !important;" :style="{ color: operatore.rHexColor + '!important' }"/> {{ operatore.rName }}</td>
                <td>{{ operatore.rCompletate }} / {{ operatore.rNumeroPrese }}</td>
                <td>
                    <span v-if="operatore.rOreStimate !== '00:00'">{{ operatore.rOreStimate }}</span>
                </td>
                <td class="text-right">
                    <router-link :to="{ path: '/utente', query: { _id: operatore._id} }" v-if="action === 'lista'">
                        <button type="button" name="button" class="button primary">
                            <i class="fas fa-list"/>
                            <span>Lista</span>
                        </button>
                    </router-link>
                    <div v-if="action === 'associa'">
                        <button type="button" name="button" class="button primary" v-if="!operatore.rAssegnato || date.data !== operatore.rDateAssociato"  @click="assegna(date.data, operatore._id)">
                            <i class="fas fa-arrow-circle-right"/>
                            <span>Assegna</span>
                        </button>
                        <button type="button" name="button" class="button primary" v-else>
                            <span>Assegnato</span>
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </v-simple-table>
    </v-container>
</template>
<script>
    import {
        momentYMD,
        momentLL
    } from "@/components/js/formatDate";
    import apiPresa from '@/components/Presa/js/presa';
    import apiLavorazioni from '@/components/Presa/lavorazioni/js/lavorazioni';
    import { generalFunction } from "@/components/js/generalFunction";
    import { EventBus } from "@/eventBus";

    export default {
        name: 'Associati',
        props: [
            'action',
            'days'
        ],
        data() {
            return {
                idPresa: this.$route.query._id,
                data: momentYMD(new Date),
                viewData: momentLL(new Date),
                calendarData: false,
                listDate: [],
                prese: [],
                allLavorazioni: [],
            }
        },
        async created() {
            let token = await generalFunction.getToken();
            if (token) {
                let lavorazioni = await apiLavorazioni.getAllLavorazioni(token);
                if (lavorazioni[1])
                    this.allLavorazioni = lavorazioni[1];
            }

            await this.initSearch();
        },
        methods: {
            changeData() {
                this.calendarData = false;
                this.viewData = momentLL(this.data);
                this.initSearch();
            },
            async initSearch() {
                this.prese = this.listDate = [];
                let token = await generalFunction.getToken();
                if (token) {
                    let data = {
                        data: this.data,
                        days: this.days
                    };
                    this.listDate.push({
                        data: momentYMD(this.data),
                        viewData: momentLL(this.data),
                    });

                    for (let day = 1; day < this.days; day++) {
                        this.listDate.push({
                            data: momentYMD(generalFunction.addDays(new Date(this.data), day)),
                            viewData: momentLL(generalFunction.addDays(new Date(this.data), day))
                        });
                    }

                    let listPrese = await apiPresa.getPreseAssociate(token, data);
                    if (
                        listPrese &&
                        listPrese[0]
                    ) this.prese = listPrese[0];
                    await this.controlOperatori();
                }
            },
            async controlOperatori() {
                let token = await generalFunction.getToken();
                if (token) {
                    let operatori = await generalFunction.getOperatori(token);
                    if (
                        operatori &&
                        operatori.length > 0
                    ) {
                        for (let [index, data] of this.listDate.entries()) {
                            data.operatori = [];
                            for (let operatore of operatori) {
                                operatore.rName = '';
                                if (operatore.nome)
                                    operatore.rName += operatore.nome;

                                operatore.rHexColor = '#ccc';
                                if (operatore.colore)
                                    operatore.rHexColor = operatore.colore;

                                operatore.rNumeroPrese = 0;
                                operatore.rCompletate = 0;
                                operatore.rOreStimate = '00:00';

                                let prese = this.prese.filter((obj) => obj.operatore_associato === operatore._id);
                                if (prese) {
                                    operatore.rOreOccupate = '00:00';
                                    for (let presa of prese) {
                                        if (
                                            presa.lavorazioni &&
                                            data.data &&
                                            presa.data_lavoro &&
                                            data.data === momentYMD(presa.data_lavoro)
                                        ) {
                                            if(presa.ore_reali) 
                                                operatore.rOreOccupate = generalFunction.sumHours(operatore.rOreOccupate, presa.ore_reali, ':');
                                                
                                            
                                            operatore.rNumeroPrese = operatore.rNumeroPrese + 1;
                                            if (presa.completata) operatore.rCompletate = operatore.rCompletate +1;
                                            if (
                                                operatore._id === presa.operatore_associato &&
                                                presa._id === this.idPresa
                                            ) {
                                                operatore.rAssegnato = true;
                                                operatore.rDateAssociato = momentYMD(presa.data_lavoro);
                                            }
                                            
                                            for (let lavorazionePresa of presa.lavorazioni) {
                                                if (
                                                    lavorazionePresa.id_lavorazione &&
                                                    lavorazionePresa.id_valore
                                                ) {
                                                    let lavorazione = this.allLavorazioni.find(lavorazione => {
                                                        return lavorazione._id === lavorazionePresa.id_lavorazione;
                                                    });

                                                    if (
                                                        lavorazione &&
                                                        lavorazione.dettagli_valore &&
                                                        lavorazione.dettagli_valore.lavorazioni_officina &&
                                                        lavorazione.dettagli_valore.lavorazioni_officina.opzioni
                                                    ) {
                                                        let option = lavorazione.dettagli_valore.lavorazioni_officina.opzioni.find(option => {
                                                            return option._id === lavorazionePresa.id_valore;
                                                        });
                                                        if (
                                                            option &&
                                                            option.tempo_stimato
                                                        ) operatore.rOreStimate = generalFunction.sumHours(operatore.rOreOccupate, option.tempo_stimato, ':');
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                data.operatori.push({ ...operatore });
                            }
                            this.$set(this.listDate, index, data);
                        }
                    }
                }
            },
            async assegna(dataLavoro, idOperatore) {
                let token = await generalFunction.getToken();
                if (token) {
                    if (
                        dataLavoro &&
                        idOperatore &&
                        this.$route.query &&
                        this.$route.query._id
                    ) {
                        let data = {
                            id_presa: this.$route.query._id,
                            id_operatore: idOperatore,
                            data_lavoro: dataLavoro
                        };
                        await apiPresa.assegnaOperatore(token, data).then(response => {
                            if (response) {
                                this.initSearch();
                                EventBus.$emit('reloadDetailPresa');
                            } else alert('Spiacenti si è verificato un errore');
                        });
                    }
                }
            }
        }
    }
</script>
