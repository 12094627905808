<template>
    <v-container fluid pl-4 pr-1 class="dettagli-wrapper">
        <v-row>
            <v-col sm="6" class="dettagli-row">
                <List />
            </v-col>
            <v-col sm="6" class="dettagli_utente dettagli-row">
                <div class="text-right close" @click="goRouterBack">
                    <i class="mdi mdi-close-circle-outline"/>
                </div>
                <Associati :action="'associa'" :days="7"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import Associati from '@/components/gestione/associati/Associati';
    import List from '@/components/utenti/Lista';

    export default {
        name: 'DettagliGestione',
        components: { Associati, List },
        methods: {
            goRouterBack() {
                this.$router.back();
            },
        }
    }
</script>
