<template>
    <div class="wrapper">
        <HeadLavorazione ref="headLavorazione" :presa="editPresa"/>
        <v-layout>
            <v-col xs="12" class="slider-wrapper">
				<v-tabs v-model="tabs"
                        background-color="transparent"
                        color="basil"
                        class="sliders"
                        grow>
                    <v-tab @click="manageSavePresa"><span class="numer">1</span>Cliente</v-tab>
                    <v-tab @click="manageSavePresa"><span class="numer">2</span>Raccolta</v-tab>
                    <v-tab @click="manageSavePresa"><span class="numer">3</span>Lavorazioni</v-tab>
                    <v-tab @click="manageSavePresa"><span class="numer">4</span>Riepilogo e stampa</v-tab>
                    <div class="text-center">
                        <v-menu>
                            <template v-slot:activator="{ on }">
                                <v-btn rounded outlined x-large color="info" class="save" v-on="on">
                                    <i class="mdi mdi-printer"/>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <a @click="manageSavePresa(); copiaMeccanico()">
                                        <v-list-item-title>Copia meccanico</v-list-item-title>
                                    </a>
                                </v-list-item>
                                <v-list-item>
                                    <a @click="manageSavePresa(); copiaSaldo()">
                                        <v-list-item-title>Saldo cliente</v-list-item-title>
                                    </a>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn rounded color="primary" x-large class="save px-10" @click="manageSavePresa()">Salva</v-btn>
                    </div>
                </v-tabs>
                <v-tabs-items v-if="editPresa" v-model="tabs" class="pt-10">
                    <Cliente ref="cliente" :presa="editPresa"/>
                    <Raccolta ref="raccolta" :presa="editPresa"/>
                    <Lavorazioni ref="lavorazioni" :presa="editPresa"/>
                    <Riepilogo ref="riepilogo" :presa="editPresa"/>
                </v-tabs-items>
            </v-col>
        </v-layout>
    </div>
</template>
<script>
import apiPresa from '@/components/Presa/js/presa';
import HeadLavorazione from '@/components/Presa/headLavorazione/HeadLavorazione';
import Cliente from '@/components/Presa/cliente/Cliente';
import Lavorazioni from '@/components/Presa/lavorazioni/Lavorazioni';
import Raccolta from '@/components/Presa/raccolta/Raccolta';
import Riepilogo from '@/components/Presa/riepilogo/Riepilogo';
import { EventBus } from "@/eventBus";
import { generalFunction } from "@/components/js/generalFunction";
import config from "@/config/config";

export default {
    name: 'Presa',
    components: {
        Cliente,
        Lavorazioni,
        HeadLavorazione,
        Raccolta,
        Riepilogo
    },
    data() {
        return {
            tabs: this.$route.query.tab ? parseInt(this.$route.query.tab) : 0,
            editPresa: {},
            loadingOrdine: false,
			loading: false
		}
    },
    computed: {
        rConfig() {
            return config;
        }
    },
    watch: {
        async '$route'(to, from) {
            await this.initPresa();
        }
    },
    async created() {
        EventBus.$emit('updateTitleNavbar', 'Presa in carico');
        await this.initPresa();
    },
    methods: {
        async initPresa() {
			this.loading = true;
            if (
                this.$route.query &&
                this.$route.query._id
            ) {
                let token = await generalFunction.getToken();
                if (token) {
                    this.editPresa = undefined;
                    let presa = await apiPresa.getPresa(token, this.$route.query._id);
                    if (
                        presa &&
                        Object.entries(presa).length > 0
                    ) {
						this.editPresa = { ...presa };
					} else await this.$router.push({ path: '/presa' });
                }
            }

			let page = this;
			setTimeout(function () {
				page.loading = false;
			}, 500)
        },
        async manageSavePresa(addingParam = '') {
			if (!this.loading) {
				let data = {};
				if (this.$refs) {
					if (this.$refs.headLavorazione) {
						let tab = this.$refs.headLavorazione;
						if (
							tab.selectedOperatorePresa &&
							tab.selectedOperatorePresa
						) data.operatore = tab.selectedOperatorePresa;
						if (tab.dataPresa)
							data.data_presa = tab.dataPresa;
						if (tab.dataConsegna)
							data.data_consegna = tab.dataConsegna;
						if (
							tab.selectedTipoPesa ||
							tab.selectedTipoPesa === 0
						) data.tipo = tab.selectedTipoPesa;
						if (tab.selectedStatoPesa)
							data.stato = tab.selectedStatoPesa;
					}
					if (this.$refs.cliente) {
						let tab = this.$refs.cliente;
						if (
							tab.selectedUser &&
							tab.selectedUser._id
						) data.id_utente = tab.selectedUser._id;

						if (
							tab.selectedBike &&
							tab.selectedBike._id
						) data.id_bici = tab.selectedBike._id;

						if (tab.richieste) data.richieste = tab.richieste;
						if (tab.lavaggio) data.lavaggio = tab.lavaggio;
						if (tab.primo_tagliando) data.primo_tagliando = tab.primo_tagliando;
						if (tab.garanzia) data.garanzia = tab.garanzia;
						if (tab.fornitore_garanzia) data.fornitore_garanzia = tab.fornitore_garanzia;
						if (tab.telefonare_quando_pronta) data.telefonare_quando_pronta = tab.telefonare_quando_pronta;

						data.spunte_presa = tab.selectedSpuntePrese;
					}
					if (this.$refs.lavorazioni) {
						let tab = this.$refs.lavorazioni;
						if (tab.noteInterne)
							data.note_interne = tab.noteInterne;
						data.lavorazioni = [];
						if (tab.selectedOptions) {
							for (let option of tab.selectedOptions) {
								data.lavorazioni.push({
									id_lavorazione: option.id_lavorazione,
									id_valore: option._id,
									prezzo: option.prezzo
								})
							}
						}
					}
					if (this.$refs.raccolta) {
						let tab = this.$refs.raccolta;
						if (tab.listArticoli)
							data.lista_articoli = [...tab.listArticoli];
						if (tab.listArticoliDaAggiungere)
							data.lista_articoli_temporanei = [...tab.listArticoliDaAggiungere];
					}
					if (this.$refs.riepilogo) {
						let tab = this.$refs.riepilogo;
						if (tab.variazioneTotale)
							data.variazione_totale = tab.variazioneTotale;
						if (tab.scontoTotale)
							data.sconto_totale = tab.scontoTotale;
						if (tab.pagato) {
							data.pagato = tab.pagato;
							data.totale = tab.totale;
						}
						if (tab.tempoPrevisto)
							data.tempo_previsto = tab.tempoPrevisto;
						if (tab.realHours)
							data.ore_reali = tab.realHours;
						if (tab.manodopera)
							data.manodopera = tab.manodopera;
                        if (tab.lavorazioni_esterne)
							data.lavorazioni_esterne = tab.lavorazioni_esterne;
					}
				}

				if (data.data_consegna) {
					let token = await generalFunction.getToken();
					if (token) {
						if (Object.entries(this.editPresa).length === 0) {
							if (addingParam) addingParam = '&' + addingParam;
							let id_presa = await apiPresa.addNewPresa(token, data);
							this.editPresa = undefined;
							let those = this;
							setTimeout(() => {
								those.$router.push({path: '/presa?_id=' + id_presa + addingParam});
							}, 50);
						} else {
							if (this.editPresa._id) {
								data._id = this.editPresa._id;
								await apiPresa.updatePresa(token, data);
								this.initPresa(this.editPresa._id)
							} else alert('Spiacenti si è verificato un errore');
						}
					}
				} else alert('Data Consegna Obbligatoria!');
			}
        },
        async creaOrdine() {
            let token = await generalFunction.getToken();
            if(
                !this.loadingOrdine &&
                token
            ) {
                this.loadingOrdine = true;
                let id_presa = this.$route.query._id;

                if(!id_presa)
                    await this.manageSavePresa();

                await this.initPresa();
                if(
                    this.editPresa &&
                    this.editPresa['_id']
                ) {
                    id_presa = this.editPresa['_id'];
                    let prodotti = this.editPresa['articoli'];

                    let id_ordine = await apiPresa.creaOrdinePresa(token, {
                        id_presa: id_presa,
                        prodotti: prodotti
                    });
                    this.editPresa['id_ordine'] = id_ordine;
                    let those = this;
                    setTimeout(async () => {
                        await those.initPresa();
                    }, 300);
                }
                this.loadingOrdine = false;
            }
        },
        copiaMeccanico() {
            if (this.$route.query._id)
                window.open(this.rConfig.domainAdmin + '/admin/prese/stampa_meccanico?_id=' + this.$route.query._id)
        },
		copiaSaldo() {
            if (this.$route.query._id)
				window.open(this.rConfig.domainAdmin + '/admin/prese/stampa?_id=' + this.$route.query._id)
        }
    }
};
</script>
