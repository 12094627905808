<template>
    <div class="content" style="padding: 20px">
        <v-row v-for="y in 30" :key="y">
            <v-col sm="1" v-for="x in 12" :key="x">
                <img class="fa-spin" style="height: 30px;float: left;" src="../assets/logo.svg" alt="">
                <h5>404</h5>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { EventBus } from "../eventBus";

    export default {
         name: "Error",
        created() {
            EventBus.$emit('updateTitleNavbar', '404');
        }
    }
</script>