<template>
    <v-app id="inspire">
        <v-content>
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="4">
                        <v-card class="login">
                            <v-toolbar dark flat>
                                <v-toolbar-title><img src="../../assets/logo-white.svg" alt=""></v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-text-field label="E-mail" name="login" v-model="email" prepend-icon="mdi-account" type="text"/>
                                    <v-text-field id="password" label="Password" v-model="password" name="password" prepend-icon="mdi-lock" type="password"/>
                                </v-form>
                                <br>
                                <span v-if="error" type="text" style="color: red; font-weight: bold">{{ error }}</span>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn class="primary" @click="login">Login</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
    import apiLogin from '@/components/login/js/login';
    const { cleaner } = require('@/components/js/cleaner.js');
    const { generalFunction } = require('@/components/js/generalFunction.js');

    export default {
        name: 'Login',
        data() {
            return {
                email: undefined,
                password: undefined,
                error: undefined
            }
        },
        methods: {
            async successLogin(token) {
                await generalFunction.setCookie('tokenofficina', token, 365);
                await this.$router.push({path: '/'});
            },
            async login() {
                this.error = undefined;
                if (
                    cleaner(this.email) &&
                    cleaner(this.password)
                ) {
                    let data = {
                        email: this.email,
                        pass: this.password
                    };
                    let token = await apiLogin.login(data);
                    if (token) this.successLogin(token);
                    else this.error = "L'utente non esiste";
                }
            }
        }
    }
</script>
