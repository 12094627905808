import moment from 'moment'
moment.locale('it');
export const momentDate = (date) => {
    if (date) return moment(String(date)).format('DD/MM/YYYY');
    else return undefined;
};

export const momentYMD = (date) => {
    if (date) return moment(String(date)).format('YYYY-MM-DD');
    else return undefined;
};

export const momentLL = (date) => {
    if (date) return moment(String(date)).format('LL');
    else return undefined;
};

export const compareDateBefore = (date1, date2) => {
    return moment(new Date(date1)).isBefore(new Date(date2));
}

export const compareDateAfter = (date1, date2) => {
    return moment(new Date(date1)).isAfter(new Date(date2));
}
