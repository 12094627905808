export const validationRules = {
    textRule: function () {
        return [
            v => !!v || 'Questo campo è richiesto',
        ];
    },
    emailRule: function () {
        return [
            v => !!v || "L'email è richiesta",
            v => /.+@.+\..+/.test(v) || "L'email deve essere valida",
        ];
    },
    selectRule: function () {
        return [v => !!v || 'Item is required'];
    }
};