<template>
    <v-tab-item class="riepilogo" style="margin: 30px">
        <v-row>
            <v-col cols="12">
                <v-card elevation="3">
                    <v-card-title class="pb-0">Riepilogo</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="2">
                                <b>Tempo impiegato</b>
                                <br>
                                <span>{{ tempoPrevisto }}</span>
                            </v-col>
                            <v-col v-if="dataConsegna" cols="2">
                                <b>Data di consegna</b>
                                <br>
                                <span>{{ dataConsegna }}</span>
                            </v-col>
                            <v-col v-if="totale" cols="2">
                                <b>Articoli</b>
                                <br>
                                <span>{{ numberFormat(totale-manodopera) }} €</span>
                            </v-col>
                            <v-col v-if="manodopera" cols="2">
                                <b>Manodopera</b>
                                <br>
                                <span>{{ numberFormat(manodopera) }} €</span>
                            </v-col>
                            <v-col v-if="lavorazioni_esterne" cols="2">
                                <b>Lavorazioni esterne</b>
                                <br>
                                <span>{{ numberFormat(lavorazioni_esterne) }} €</span>
                            </v-col>
                            <v-col v-if="totale" cols="2">
                                <b>Totale</b>
                                <br>
                                <span>{{ numberFormat(totale) }} €</span>
                            </v-col>
                            <v-col v-if="totale" cols="2">
                                <b>Punti <small v-if="ultimaModificaTessera">(ultima modifica {{ ultimaModificaTessera }})</small></b>
                                <br>
                                <span style="margin-right: 10px">{{ puntiTessera }}/10</span>
                                <v-btn rounded
                                       color="secondary"
                                       style="margin-right: unset; margin-left: 2px; min-width: unset; height: unset; padding: unset"
                                       @click="aggiornaPuntiTessera('aggiungi')"
                                >
                                    <i class="mdi mdi-plus"/>
                                </v-btn>
                                <v-btn rounded
                                       color="warning"
                                       style="margin-right: unset; margin-left: 2px; min-width: unset; height: unset; padding: unset"
                                       @click="aggiornaPuntiTessera('rimuovi')"
                                >
                                    <i class="mdi mdi-minus"/>
                                </v-btn>
                                <v-btn rounded
                                       color="alert"
                                       style="margin-right: unset; margin-left: 2px; min-width: unset; height: unset; padding: unset"
                                       @click="aggiornaPuntiTessera('azzera')"
                                >
                                    <i class="mdi mdi-close"/>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="ordiniSaldo && ordiniSaldo.length">
            <v-col cols="12">
                <v-simple-table class="table-raccolta">
                    <thead>
                    <tr>
                        <th class="text-left">Numero ordine</th>
                        <th class="text-left">Data</th>
                        <th class="text-left">Totale</th>
                        <th class="text-left">Pagato</th>
                        <th class="text-left">Stato</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="ordine in ordiniSaldo" :key="ordine.rId" @click="goToOrder(ordine.rId)">
                        <td>#{{ ordine.numero }}</td>
                        <td>{{ ordine.data }}</td>
                        <td>{{ ordine.totale }}</td>
                        <td>
                            <i v-if="ordine.pagato" class="fas fa-check-circle colorPrimary"></i>
                            <i v-else class="fas fa-times-circle colorError"></i>
                        </td>
                        <td>
                            <v-badge :color="ordine.colore_stato" :content="ordine.stato" />
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-alert type="info" text variant="tonal">
                    Non è stato ancora creato un ordine di saldo
                </v-alert>
            </v-col>
        </v-row>
    </v-tab-item>
</template>
<script>
import apiUser from '@/components/utenti/js/user';
import { EventBus } from "@/eventBus";
import { generalFunction } from "@/components/js/generalFunction";
import { momentLL } from "@/components/js/formatDate";
import config from "@/config/config";
import apiLavorazioni from "@/components/Presa/lavorazioni/js/lavorazioni";

export default {
	name: 'Riepilogo',
	props: ['presa'],
	data() {
		return {
			dataConsegna: undefined,
			calculatedManodopera: 0,
			manodopera: 0,
            lavorazioni_esterne: 0,
			totaleOrdini: 0,
			tempoPrevisto: "00:00",
			totale: 0,
			puntiTessera: 0,
			ultimaModificaTessera: '',
			allLavorazioni: [],
			ordiniSaldo: []
		}
	},
	computed: {
		rConfig() {
			return config;
		}
	},
	watch: {
		presa() {
			this.initSummary();
		}
	},
	async created() {
		let token = await generalFunction.getToken();
		if (token) await this.initDataLavorazioni(token);

		this.initSummary();
	},
	methods: {
		async initDataLavorazioni(token) {
			let lavorazioni = await apiLavorazioni.getAllLavorazioni(token);
			if (lavorazioni[1]) {
				for (let lavorazione of lavorazioni[1]) {
					lavorazione.rName = 'Senza nome';
					if (lavorazione.it && lavorazione.it.nome)
						lavorazione.rName = lavorazione.it.nome;

					lavorazione.rCategoria = undefined;
					lavorazione.rOptions = [];
					if (
						lavorazione.dettagli_valore &&
						lavorazione.dettagli_valore.lavorazioni_officina
					) {
						if (lavorazione.dettagli_valore.lavorazioni_officina.categoria)
							lavorazione.rCategoria = lavorazione.dettagli_valore.lavorazioni_officina.categoria;

						if (lavorazione.dettagli_valore.lavorazioni_officina.opzioni) {
							for (let opzione of lavorazione.dettagli_valore.lavorazioni_officina.opzioni) {
								opzione.id_lavorazione = lavorazione._id;
								opzione.rName = 'Senza nome';
								if (opzione.nome) opzione.rName = opzione.nome;
							}
							lavorazione.rOptions = lavorazione.dettagli_valore.lavorazioni_officina.opzioni;
						}
					}
				}
			}
			this.allLavorazioni = lavorazioni[1];
		},
		initSummary() {
			let manodopera = 0;
			let lavorazioni_esterne = 0;
			let totaleOrdini = 0;
			let tempoPrevisto = "00:00";
			this.totale = 0;
			if (
				this.$parent &&
				this.$parent.$parent &&
				this.$parent.$parent.$refs &&
				this.$parent.$parent.$refs.cliente
			) {
				if (this.$parent.$parent.$refs.cliente.puntiTessera)
					this.puntiTessera = this.$parent.$parent.$refs.cliente.puntiTessera
				if (
					this.$parent.$parent.$refs.cliente.selectedUser &&
					this.$parent.$parent.$refs.cliente.selectedUser.dettagli_utente &&
					this.$parent.$parent.$refs.cliente.selectedUser.dettagli_utente.tessera_punti &&
					this.$parent.$parent.$refs.cliente.selectedUser.dettagli_utente.tessera_punti.data
				) this.ultimaModificaTessera = momentLL(this.$parent.$parent.$refs.cliente.selectedUser.dettagli_utente.tessera_punti.data);
			}

			if (this.presa.data_consegna) this.dataConsegna = momentLL(this.presa.data_consegna);

			if (
				this.presa.lavorazioni &&
				this.presa.lavorazioni.length > 0
			) {
				for (let mainLavorazione of this.allLavorazioni) {
					if (
						mainLavorazione &&
						mainLavorazione.rOptions &&
						mainLavorazione.rOptions.length > 0
					) {
						for (let lavorazione of this.presa.lavorazioni) {
							if (
								mainLavorazione._id === lavorazione.id_lavorazione &&
								lavorazione.id_valore
							) {
								let option = mainLavorazione.rOptions.find(option => {
									return option._id === lavorazione.id_valore;
								});
								if (Object.entries(option).length > 0) {
									if (
										option.prezzo &&
										!isNaN(option.prezzo) &&
										option.prezzo > 0 &&
                                        mainLavorazione.rCategoria !== 'esterna'
									) manodopera += option.prezzo;

									if (mainLavorazione.rCategoria === 'esterna')
                                        lavorazioni_esterne += lavorazione.prezzo;

									if (option.tempo_stimato)
										tempoPrevisto = generalFunction.sumHours(tempoPrevisto, option.tempo_stimato, ':');
								}
							}
						}
					}
				}

				tempoPrevisto = tempoPrevisto.split(':')
				if (tempoPrevisto[0])
					tempoPrevisto[0] = ('0'+tempoPrevisto[0]).slice(-2);
				if (tempoPrevisto[1])
					tempoPrevisto[1] = ('0'+tempoPrevisto[1]).slice(-2);

				tempoPrevisto = tempoPrevisto.join(':')
				this.tempoPrevisto = tempoPrevisto;
			}

			this.manodopera = manodopera;
			this.lavorazioni_esterne = lavorazioni_esterne;
			this.totale += manodopera;
			this.totale += lavorazioni_esterne;

			if (
				this.presa.ordiniOfficina &&
				this.presa.ordiniOfficina.length > 0
			) {
                for (let ordineOfficina of this.presa.ordiniOfficina)
					if (ordineOfficina.totale)
						totaleOrdini += ordineOfficina.totale;
            }

			this.totale += totaleOrdini;

			let ordiniSaldo = [];
			if (
				this.presa &&
				this.presa.ordiniSaldo &&
				this.presa.ordiniSaldo.length
			) {
				for (let order of this.presa.ordiniSaldo) {
					let rNumero = '';
					let rDate = '';
					let rTotale = '';
					let rStato = '';
					let rColoreStato = '';
					if (order.numero_cassa) rNumero = order.numero_cassa;
					if (order.totale) rTotale = order.totale + ' €';
					if (order.data_creazione) rDate = momentLL(order.data_creazione);
					if (
						order.stato_ordine &&
						order.full_stato &&
						order.full_stato.length
					) {
						for (let stato of order.full_stato) {
							if (
								stato.it &&
								stato.it.nome &&
								String(stato._id) === String(order.stato_ordine)
							) {
								rStato = stato.it.nome;
								if (stato.colore) rColoreStato = stato.colore;
							}
						}
					}
					ordiniSaldo.push({
						rId: order._id,
						numero: rNumero,
						data: rDate,
						totale: rTotale,
						stato: rStato,
						colore_stato: rColoreStato,
                        pagato: order.pagamento && order.pagamento.completato
					})
				}
			}

			this.ordiniSaldo = ordiniSaldo;
		},
		numberFormat(value = 0) {
			if(!isNaN(value))
				return parseFloat(value).toFixed(2).split(".").join(',');
			return 0;
		},
		async aggiornaPuntiTessera(azione) {
			let token = await generalFunction.getToken();
			if (
				azione &&
				this.$parent &&
				this.$parent.$parent &&
				this.$parent.$parent.$refs &&
				this.$parent.$parent.$refs.cliente &&
				this.$parent.$parent.$refs.cliente.selectedUser &&
				this.$parent.$parent.$refs.cliente.selectedUser._id
			) {
				if (
					azione === 'aggiungi' ||
					azione === 'rimuovi'
				) {
					if (azione === 'aggiungi') this.puntiTessera = this.puntiTessera + 1;
					if (azione === 'rimuovi') this.puntiTessera = this.puntiTessera - 1;

					if (this.puntiTessera < 0) this.puntiTessera = 0;
					if (this.puntiTessera > 10) this.puntiTessera = 10;

					let data = {
						id_utente: this.$parent.$parent.$refs.cliente.selectedUser._id,
						punti: this.puntiTessera
					}
					await apiUser.aggiornaTessera(token, data);
				}
				if (azione === 'azzera') {
					this.puntiTessera = 0;
					let data = {
						id_utente: this.$parent.$parent.$refs.cliente.selectedUser._id,
					}
					await apiUser.azzeraTessera(token, data);
				}
			}
		},
		goToOrder(idOrdine) {
			if (idOrdine) window.open(this.rConfig.domainAdmin + '/admin/ordini/?_id='+idOrdine);
			else alert('Spiacenti si è verificato un errore!');
		}
	}
}
</script>
