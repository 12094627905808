<template>
    <v-container class="consegna" fluid pl-4 pr-4>
        <v-col sm="12">
            <i class="mdi mdi-close-circle-outline close" @click="goRouterBack"/>
        </v-col>
        <!--HeadConsegna /-->
        <h3>In consegna</h3>
        <v-row>
            <v-col cols="3">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Cerca"
                    single-line
                    dense
                    hide-details
                    :loading="isLoadingRicerca"
                    @input="initStartSearch()"
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-data-table class="content-table"
                              :headers="headers"
                              :items="listPrese"
                              must-sort
                              :loading="isLoadingRicerca"
                              :search="search"
                              :items-per-page="20"
                              :options="optionsTable"
                              :footer-props="footerProps"
                              :server-items-length="lengthListPrese"
                              @pagination="updatePage"
                >
                    <template v-slot:item.numero="{item}">
                        # {{ item.numero }}
                    </template>
                    <template v-slot:item.rManodopera="{item}">
                        {{ numberFormat(item.rManodopera) }} €
                    </template>
                    <template v-slot:item.rLavorazioniEsterne="{item}">
                        {{ numberFormat(item.rLavorazioniEsterne) }} €
                    </template>
                    <template v-slot:item.prezzoArticoli="{item}">
                        {{ numberFormat(item.prezzoArticoli) }} €
                    </template>
                    <template v-slot:item.totale="{item}">
                        {{ numberFormat(item.totale) }} €
                    </template>
                    <template v-slot:item.completata="{item}">
                        <i v-if="item.completata" class="fas fa-check-circle"></i>
                        <i v-else class="fas fa-times-circle non-consegnata"></i>
                    </template>
                    <template v-slot:item.pagamento.pagato="{item}">
                        <button type="button" name="button">
                            <label class="fancy-checkbox in-form">
                                <input type="checkbox" :checked="item.pagamento && item.pagamento.pagato">
                                <i class="fas fa-check checked"/>
                                <i class="far fa-circle unchecked"/>
                            </label>
                        </button>
                    </template>
                    <template v-slot:item.stampa="{item}">
                        <i style="color: #222" class="fas fa-print" @click="apriPagina(rConfig.domainAdmin + '/admin/prese/stampa?_id='+item._id)"/>
                    </template>
                    <template v-slot:item.edit="{item}">
                        <router-link :to="{ path: '/lista', query: { _id: item._id }}">
                            <i class="far fa-edit"></i>
                        </router-link>
                    </template>
                    <template v-slot:item.consegnata="{item}">
                        <button type="button" name="button" class="button primary button-censegna">
                            <label class="fancy-checkbox in-form">
                                <input type="checkbox" v-model="item.consegnata" :checked="item.consegnata" @change="presaConsegnata(item._id, item.consegnata)">
                                <i class="fas fa-check checked"/>
                                <i class="far fa-circle unchecked"/>
                            </label>
                            <span>Consegnata</span>
                        </button>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { momentLL } from "@/components/js/formatDate";
import apiPresa from '@/components/Presa/js/presa';
import apiLavorazioni from '@/components/Presa/lavorazioni/js/lavorazioni';
import { generalFunction } from "@/components/js/generalFunction";
import { EventBus } from "../../../eventBus";
import config from "@/config/config";

// import HeadConsegna from '@/components/gestione/consegna/HeadConsegna'
export default {
    name: 'Consegna',
    //components: { HeadConsegna },
    data() {
        return {
            allLavorazioni: [],
            listPrese: [],
            headers: [
                { text: '#', value: 'numero' },
                { text: 'CLIENTE', value: 'rNameUser' },
                { text: 'BICI', value: 'rNameBike' },
                { text: 'OPERATORE', value: 'rNameOperatore' },
                { text: 'MANODOPERA', value: 'rManodopera' },
                { text: 'LAVORAZIONI ESTERNE', value: 'rLavorazioniEsterne' },
                { text: 'ARTICOLI', value: 'prezzoArticoli' },
                { text: 'TOTALE', value: 'totale' },
                { text: 'COMPLETATA', value: 'completata' },
                { text: 'PAGATO', value: 'pagamento.pagato' },
                { text: '', value: 'stampa' },
                { text: '', value: 'edit' },
                { text: '', value: 'consegnata' },
            ],
            search: '',
            page: 1,
            optionsTable: {
                page: 1,
                itemsPerPage: 20,
                sortBy: ['numero'],
                sortDesc: [true],
                multiSort: false,
                mustSort: true
            },
            footerProps: {
                'disable-items-per-page': true,
                'items-per-page-all-text': 'Tutti',
                'items-per-page-text': 'Elementi per pagina',
                pageText: '{0}-{1} di {2}'
            },
            lengthListPrese: 0,
            timeoutRicerca: undefined,
            isLoadingRicerca: false,
        }
    },
    async created() {
        EventBus.$emit('updateTitleNavbar', 'In consegna');

        let token = await generalFunction.getToken();
        if (token) {
            let lavorazioni = await apiLavorazioni.getAllLavorazioni(token);
            if (lavorazioni[1]) {
                for (let lavorazione of lavorazioni[1]) {
                    lavorazione.rName = 'Senza nome';
                    if (lavorazione.it && lavorazione.it.nome)
                        lavorazione.rName = lavorazione.it.nome;

                    lavorazione.rCategoria = undefined;
                    if (
                        lavorazione.dettagli_valore &&
                        lavorazione.dettagli_valore.lavorazioni_officina
                    ) {
                        if (lavorazione.dettagli_valore.lavorazioni_officina.categoria)
                            lavorazione.rCategoria = lavorazione.dettagli_valore.lavorazioni_officina.categoria;
                    }
                }

                this.allLavorazioni = lavorazioni[1];
            }
        }
        this.initSearchPresa();
    },
    methods: {
        goRouterBack() {
            this.$router.back();
        },
        async initSearchPresa() {
            this.isLoadingRicerca = true;
            let token = await generalFunction.getToken();
            if (token) {
                let data = {
                    data_consegna: new Date(),
                    cerca: this.search,
                    page: this.page
                };
                let prese = await apiPresa.searchPrese(token, data);
                if (
                    prese &&
                    prese[0]
                ) {
                    for (let presa of prese[0]) {
                        presa.rNameUser = '';
                        presa.rNameBike = '';

                        if (presa.data_presa)
                            presa.viewDataPresa = momentLL(presa.data_presa);

                        if (presa.data_consegna)
                            presa.viewDataConsegna = momentLL(presa.data_consegna);

                        if (presa.data_lavoro)
                            presa.viewDataLavoro = momentLL(presa.data_lavoro);

                        if (
                            presa.user &&
                            presa.user[0]
                        ) {
                            if (presa.user[0].nome)
                                presa.rNameUser += presa.user[0].nome;

                            if (presa.user[0].cognome)
                                presa.rNameUser += ' ' + presa.user[0].cognome;

                            if (
                                presa.id_bici &&
                                presa.user[0].dettagli_utente &&
                                presa.user[0].dettagli_utente.bici
                            ) {
                                let bike = presa.user[0].dettagli_utente.bici.find(bike => {
                                    return bike._id === presa.id_bici;
                                });

                                if (
                                    bike &&
                                    bike.modello
                                ) presa.rNameBike = bike.modello;
                            }
                        }

                        if (
                            presa.operatore &&
                            presa.operatore[0]
                        ) {
                            if (presa.operatore[0].nome)
                                presa.rNameOperatore = presa.operatore[0].nome;
                            else if (presa.operatore[0].cognome)
                                presa.rNameOperatore = presa.operatore[0].cognome;
                        }

                        presa.totale = 0;
                        presa.rManodopera = 0;
                        presa.rLavorazioniEsterne = 0;
                        if (presa.lavorazioni) {
                            for (let lavorazionePresa of presa.lavorazioni) {
                                if (
                                    lavorazionePresa.id_lavorazione &&
                                    lavorazionePresa.id_valore
                                ) {
                                    let lavorazione = this.allLavorazioni.find(lavorazione => {
                                        return lavorazione._id === lavorazionePresa.id_lavorazione;
                                    });

                                    if (
                                        lavorazione &&
                                        lavorazione.dettagli_valore &&
                                        lavorazione.dettagli_valore.lavorazioni_officina &&
                                        lavorazione.dettagli_valore.lavorazioni_officina.opzioni
                                    ) {
                                        let option = lavorazione.dettagli_valore.lavorazioni_officina.opzioni.find(option => {
                                            return option._id === lavorazionePresa.id_valore;
                                        });
                                        if (option) {
                                            if (
                                                option.prezzo &&
                                                !isNaN(option.prezzo) &&
                                                option.prezzo > 0 &&
                                                lavorazione.rCategoria !== 'esterna'
                                            ) presa.rManodopera += Number(option.prezzo);

											if (lavorazione.rCategoria === 'esterna') {
												presa.rLavorazioniEsterne += lavorazionePresa.prezzo;
												option.prezzo = lavorazionePresa.prezzo
											}
                                        }
                                    }
                                }
                            }
                        }

                        presa.prezzoArticoli = 0;
                        if (presa.articoli) {
                            for (let articoliPresa of presa.articoli) {
                                if (
                                    articoliPresa.prezzo &&
                                    !isNaN(articoliPresa.prezzo) &&
                                    articoliPresa.prezzo > 0
                                ) presa.prezzoArticoli += Number(articoliPresa.prezzo);
                            }
                        }
                        presa.totale += presa.rManodopera;
                        presa.totale += presa.rLavorazioniEsterne;
                        presa.totale += presa.prezzoArticoli;
                        if (presa.variazione_totale) presa.totale += presa.variazione_totale;
                        if (presa.sconto_totale) presa.totale =  presa.totale -  presa.totale * presa.sconto_totale / 100;
                    }
                    this.listPrese = prese[0];
                    this.lengthListPrese = prese[1];
                }
            }
            this.isLoadingRicerca = false;
        },
        updatePage(pagination) {
            this.page = pagination.page;
            this.initSearchPresa();
        },
        async presaConsegnata(idPresa, consegnata) {
            let token = await generalFunction.getToken();
            if (token) {
                if (idPresa) {
                    let data = {
                        id_presa: idPresa,
                        consegnata: consegnata,
                    };

                    await apiPresa.consegnaPresa(token, data);
                }
            }
        },
        numberFormat(value = 0) {
            if(!isNaN(value))
                return parseFloat(value).toFixed(2).split(".").join(',');
            return 0;
        },
        initStartSearch() {
            clearTimeout(this.timeoutRicerca);
            let those = this;
            this.timeoutRicerca = setTimeout(function () {
                those.initSearchPresa();
            }, 500);
        },
        apriPagina(url) {
            window.open(url)
        }
    },
    computed: {
        rConfig() {
            return config;
        }
    }
}
</script>
